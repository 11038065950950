import { Box, Button, Switch, Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import InfoIcon from '@mui/icons-material/Info'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'

const renderTooltip = (title) => (
  <Tooltip title={title}>
    <Button>
      <InfoIcon className="security-info-icon" />
    </Button>
  </Tooltip>
)

const KybRiskMatrixSettings = ({
  riskMatrixSkipNoDataFound,
  runRiskMatrixOnUpdate,
  setRiskMatrixSettings,
  isLoading,
}) => {
  const { t } = useTranslation(['common', 'messages'])
  const [currentRiskMatrixSkipNoDataFound, setCurrentRiskMatrixSkipNoDataFound] = useState(riskMatrixSkipNoDataFound)
  const [currentRunRiskMatrixOnUpdate, setCurrentRunRiskMatrixOnUpdate] = useState(runRiskMatrixOnUpdate)
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false)

  useEffect(() => {
    const hasChanges =
      currentRiskMatrixSkipNoDataFound !== riskMatrixSkipNoDataFound ||
      currentRunRiskMatrixOnUpdate !== runRiskMatrixOnUpdate
    setIsSaveButtonEnabled(hasChanges)
  }, [currentRiskMatrixSkipNoDataFound, currentRunRiskMatrixOnUpdate, riskMatrixSkipNoDataFound, runRiskMatrixOnUpdate])

  const handleSave = () => {
    setRiskMatrixSettings({
      riskMatrixSkipNoDataFound: currentRiskMatrixSkipNoDataFound,
      runRiskMatrixOnUpdate: currentRunRiskMatrixOnUpdate,
    })
  }

  return (
    <Box>
      <Box display="flex" gap="36px">
        <Box display="flex" alignItems="center">
          <p>{t('messages:kyb.settings.riskMatrixSkipNoDataFound')}</p>
          <Box className="security-flex-label">
            {renderTooltip(
              <Box className="back-tip">
                <label className="security-items-info" color="inherit">
                  {t('messages:kyb.settings.riskMatrixSkipNoDataFound.tooltip')}
                </label>
              </Box>,
            )}
            <Switch
              color="secondary"
              name="checkPasswordHistoryLength"
              id="checkPasswordHistoryLength"
              onChange={(e) => setCurrentRiskMatrixSkipNoDataFound(e.target.checked)}
              checked={currentRiskMatrixSkipNoDataFound}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <p>{t('messages:kyb.settings.runRiskMatrixOnUpdate')}</p>
          <Box className="security-flex-label">
            {renderTooltip(
              <Box className="back-tip">
                <label className="security-items-info" color="inherit">
                  {t('messages:kyb.settings.runRiskMatrixOnUpdate.tooltip')}
                </label>
              </Box>,
            )}
            <Switch
              color="secondary"
              name="runRiskMatrixWhenUpdate"
              id="runRiskMatrixWhenUpdate"
              onChange={(e) => setCurrentRunRiskMatrixOnUpdate(e.target.checked)}
              checked={currentRunRiskMatrixOnUpdate}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          disabled={!isSaveButtonEnabled || isLoading}
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default KybRiskMatrixSettings
