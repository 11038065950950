import { Box, Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LinkedEntities = ({ linkedEntities }) => {
  const { t } = useTranslation()

  return (
    <Box className="user-details-wrapper">
      <Box className="user-box">
        <p className="datatable-modal-title">{t('common:parentUserId')}</p>
        <Box className="user-info-3">
          <Chip variant="transparent" label={linkedEntities?.parentUserId ? linkedEntities.parentUserId : '---'} />
        </Box>
      </Box>

      <Box className="user-box">
        <p className="datatable-modal-title">{t('common:childUserId')}</p>
        <Box className="user-info-3">
          {linkedEntities.childUserIds.length &&
            linkedEntities.childUserIds.map((id, index) => {
              return <Chip variant="transparent" key={index} label={id} />
            })}
          {!linkedEntities.childUserIds.length && <p className="tags">---</p>}
        </Box>
      </Box>
    </Box>
  )
}

export default LinkedEntities
