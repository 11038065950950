import { useDispatch } from 'react-redux'
import { Box, Button, FormControl, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined'
import { useTranslation } from 'react-i18next'
import { addKybFilter } from '../../../features/kyb/kybSlice'
import { addKycFilter } from '../../../features/kybPerson/kybPersonSlice'
import { documentByTypeAndCountry, searchTypes } from '../constants'

const KybSearch = ({ viewType, country, searchType, setSearchType, searchText, setSearchText, handleSearch }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])

  const documentTypes = documentByTypeAndCountry[viewType][country] || []
  const documentTypesText = `(${documentTypes?.join(', ')})`

  const handleChangeText = (e) => {
    if (searchType === 'documentNumber') {
      setSearchText(e.target.value?.replace(/[^0-9]/g, ''))
    } else {
      setSearchText(e.target.value)
    }
  }

  const handleKeyDown = (e) => {
    // eslint-disable-next-line
    if (e.keyCode == 13) {
      handleSearch()
    }
  }

  const handleReset = () => {
    setSearchText('')
    if (viewType === 'kyb') {
      dispatch(addKybFilter({ searchText: '' }))
    } else {
      dispatch(addKycFilter({ searchText: '' }))
    }
  }

  return (
    <Box mb={2}>
      <FormControl sx={{ m: 1, width: 320 }}>
        <Select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
          renderValue={(value) => (
            <div style={{ display: 'flex', gap: 4 }}>
              <div>{t(`common:kyb.searchTypeLabel`)}</div>
              <div style={{ fontWeight: 'bold' }}>
                {t(`common:kyb.searchType.${value}`, { documentTypes: documentTypesText })}
              </div>
            </div>
          )}
        >
          {searchTypes.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {t(`common:kyb.searchType.${item}`, { documentTypes: documentTypesText })}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 400 }}>
        <TextField
          value={searchText}
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
          placeholder={t(`common:kyb.searchTypePlaceholder.${searchType}`, { documentTypes: documentTypesText })}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton alt="Clear" disabled={!searchText} onClick={handleReset}>
                  <ClearIcon />
                </IconButton>
                {searchType === 'documentNumber' && (
                  <Tooltip
                    placement="top"
                    title={
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {documentTypes?.map((documentType) => (
                          <div key={documentType}>{t(`common:kyb.searchTextHelp.document.${documentType}`)}</div>
                        ))}
                      </div>
                    }
                  >
                    <QuestionMarkOutlinedIcon />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: 100 }}>
        <Button
          size="small"
          variant="contained"
          type="submit"
          disabled={searchText === ''}
          onClick={() => handleSearch()}
        >
          {t('common:search')}
        </Button>
      </FormControl>
    </Box>
  )
}

export default KybSearch
