import './caseManagement.scss'
import { useEffect, useState } from 'react'
import { Box, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import GeneralAlertsDatatable from './alerts/GeneralAlertsTable'
import { updateGeneralAlertsFilters } from '../../features/generalAlerts/generalAlertsSlice'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CaseStatus } from './case.status'
import { FilterModal } from '../../components/utilities/FilterModal'

const CaseManagement = () => {
  const { emailClient } = useSelector((state) => state.login)
  const caseQueryState = useSelector((state) => state.case.filters)
  // const alertQueryState = useSelector((state) => state.alert.filters)
  const generalAlertsQueryState = useSelector((state) => state.generalAlerts.filters)
  const [isLoading, setIsLoading] = useState(true)
  const [caseId, setCaseId] = useState(caseQueryState.caseId)
  const [alertType, setAlertType] = useState('')
  const [generalAlertId, setGeneralAlertId] = useState(generalAlertsQueryState.id)
  const [status, setStatus] = useState('')
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [selectedFilters, setSelectedFilters] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const languageState = useSelector((state) => state.language.lang)

  useEffect(() => {
    dayjs.locale(languageState)
  }, [languageState])

  const handleResetButton = () => {
    handleGeneralAlertResetButton()
  }

  const handleDispatch = () => {
    setPage(0)

    handleGeneralAlertsDispatch()

    return setModalOpen(false)
  }

  const handleGeneralAlertResetButton = () => {
    if (generalAlertId !== '') setGeneralAlertId('')
    if (alertType !== '') setAlertType('')
    if (fromDate !== '') setFromDate(null)
    setPage(0)
    setIsLoading(true)

    return dispatch(
      updateGeneralAlertsFilters({
        viewMode: true,
        emailClient: emailClient,
        id: '',
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        type: '',
        fromDate: null,
        toDate: '',
      }),
    )
  }

  const handleGeneralAlertsDispatch = () => {
    const selectedStartOfDay = fromDate ? dayjs(fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    const todayEndOfDay = toDate ? dayjs(toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setIsLoading(true)
    return dispatch(
      updateGeneralAlertsFilters({
        viewMode: true,
        emailClient: emailClient,
        id: generalAlertId,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        type: alertType,
        fromDate: selectedStartOfDay,
        toDate: todayEndOfDay,
      }),
    )
  }

  useEffect(() => {
    handleGeneralAlertsDispatch()
  }, [rowsPerPage, page, status])

  useEffect(() => {
    if (fromDate) {
      const formattedDate = new Date(fromDate)
      setToDate(fromDate ? formattedDate.setDate(formattedDate.getDate() + 1) : null)
    }
  }, [fromDate])

  useEffect(() => {
    if (!fromDate && toDate) {
      const formattedDate = new Date(toDate)
      formattedDate?.setHours(0)
      formattedDate?.setMinutes(0)
      formattedDate?.setSeconds(0)
      setFromDate(formattedDate)
    }
  }, [toDate])

  useEffect(() => {
    if (fromDate && !toDate) {
      const formattedDate = new Date(fromDate)
      formattedDate?.setHours(23)
      formattedDate?.setMinutes(59)
      formattedDate?.setSeconds(59)
      setToDate(formattedDate ?? null)
    }
  }, [fromDate])

  return (
    <Box>
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t('common:alerts')}</Typography>
        </Box>
        <Typography variant="subtitle3" />
      </Box>

      <Paper elevation={0}>
        <GeneralAlertsDatatable
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setPage={setPage}
          setCount={setCount}
          count={count}
          type={alertType}
          withPagination={true}
          setModalOpen={setModalOpen}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          hasTabs="23rem"
        />
      </Paper>

      {/* MODAL */}
      <FilterModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:by')} ID:</Typography>
            <TextField
              placeholder={t('common:searchById')}
              required
              value={caseId}
              onChange={(event) => setCaseId(event.target.value)}
              size="small"
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:state')}:
            </Typography>
            <Select
              fullWidth
              size="small"
              displayEmpty
              value={status}
              onChange={(e) => {
                if (e.target.value) {
                  setStatus(e.target.value)
                  setPage(0)
                } else setStatus(null)
              }}
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:state')}
                </Typography>
              </MenuItem>
              {CaseStatus.map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name.replaceAll('_', ' ')}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:initialDate')}:</Typography>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <DatePicker
                value={dayjs(fromDate)}
                onChange={(newValue) => {
                  setSelectedFilters([...selectedFilters, newValue])
                  return setFromDate(newValue ? newValue['$d'] : null)
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">{t('common:endDate')}:</Typography>
            <LocalizationProvider
              size="small"
              dateAdapter={AdapterDayjs}
              adapterLocale={languageState === 'es-ES' ? 'es' : languageState === 'pt-BR' ? 'pt-br' : languageState}
            >
              <DatePicker
                size="small"
                value={dayjs(toDate)}
                onChange={(newValue) => {
                  let aux = newValue ? new Date(newValue['$d']) : null
                  aux?.setHours(23)
                  aux?.setMinutes(59)
                  aux?.setSeconds(59)
                  setToDate(aux)
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:alert')} ID:
            </Typography>

            <TextField
              variant="outlined"
              size="small"
              value={generalAlertId}
              onChange={(event) => setGeneralAlertId(event.target.value)}
              placeholder={`${t('common:alert')} ID`}
            />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:type')}:
            </Typography>

            <Select
              value={alertType}
              onChange={(e) => setAlertType(e.target.value)}
              fullWidth
              size="small"
              displayEmpty
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:type')}
                </Typography>
              </MenuItem>
              {['aml', 'amlCrypto', 'transactionRule'].map((name, index) => (
                <MenuItem key={index} value={name}>
                  {name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>
    </Box>
  )
}

export default CaseManagement
