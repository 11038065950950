import '../home/home.scss'
import '../onboarding/onboarding.scss'
import './kyb.scss'
import {
  Box,
  Tab,
  Select,
  MenuItem,
  Modal,
  Typography,
  InputLabel,
  Paper,
  Link,
  Fab,
  TablePagination,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Link as ReactRouterLink } from 'react-router-dom'
import '../../components/common/alertFetchSpinner/alertFetchSpinner.scss'
import AlertFetchSpinner from '../../components/common/alertFetchSpinner/alertFetchSpinner'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../../components/common/time/timeHelper'
import { RiskLevel } from '../../components/utilities/RiskLevel'
import { updateKybNotificationsFilters } from '../../features/kybNotifications/kybNotificationsSlice'
import Spinner from '../../components/common/spinner/spinner'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { kybRiskLevelEnum } from '../../pages/kyb/kyb.enums'
import { TableIcons } from '../../components/utilities/TableIcons'
import ProcessingSpinner from '../../components/common/alertFetchSpinner/processingSpinner'
import MyDatePicker from '../../components/utilities/MyDatePicker'
import { FilterModal } from '../../components/utilities/FilterModal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { setIsLoading } from '../../features/kyb/kybCountrySlice'
import SettingsIcon from '@mui/icons-material/Settings'
import KybNotificationsSettings from './components/KybNotificationsSettings'
import { getCountrySegment } from './kyb.hook-helpers'
import { useGetKybNotificationsQuery, useRestartKybMutation } from '../../services/guenoApi'
import AlertFetchSpinnerAutoClose from '../../components/common/alertFetchSpinner/AlertFetchSpinnerAutoClose'
import { DataGridPro, GridColumnMenu, useGridApiRef } from '@mui/x-data-grid-pro'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useMemo } from 'react'
import dayjs from 'dayjs'

const CustomTabList = styled(TabList)({
  borderBottom: '1px solid #1C282026',
  '& .MuiTabs-flexContainer': {
    gap: '1rem !important',
    display: 'flex',
  },
})

const statusEnum = ['COMPLETED', 'PENDING', 'CANCELED']

const tabToStatusMap = {
  pending: 'PENDING',
  completed: 'COMPLETED',
  canceled: 'CANCELED',
}

const autosizeOptions = {
  includeHeaders: true,
  includeOutliers: true,
  expand: true,
}

const KybNotifications = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tab } = useParams()
  const { accessToken } = useSelector((state) => state.login)
  const { isLoading } = useSelector((state) => state.kybCountry)
  const { theme } = useSelector((state) => state.theme)
  const { countrySegment } = useSelector((state) => state.kybCountry)
  const queryState = useSelector((state) => state.kybNotifications.filters)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [rowsPerPageOutDate, setRowsPerPageOutDate] = useState(25)
  const [pageOutDate, setPageOutDate] = useState(0)
  const [page, setPage] = useState(queryState.page)
  const { limit, offset } = queryState
  const [id, setId] = useState(queryState.id)
  const [status, setStatus] = useState(queryState.status ?? 'INACTIVE')
  const [toDate, setToDate] = useState(queryState.toDate)
  const [riskLevel, setRiskLevel] = useState(queryState.riskLevel ?? '')
  const [selectedDate, setSelectedDate] = useState(queryState.fromDate)
  const [fetchError, setFetchError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [isLocallyLoading, setIsLocallyLoading] = useState(false)
  const [fetchMessage, setFetchMessage] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [newStatus, setNewStatus] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [activeNotification, setActiveNotification] = useState('')
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const { t } = useTranslation(['common', 'messages'])
  const [newExpirationDate, setNewExpirationDate] = useState('')
  const [sortField, setSortField] = useState('expirationDate')
  const [sortDirection, setSortDirection] = useState(1)
  const [sortFieldOutDate, setSortFieldOutDate] = useState('expirationDate')
  const [sortDirectionOutDate, setSortDirectionOutDate] = useState(-1)
  const [type, setType] = useState('')

  const filterStatus = tabToStatusMap[tab] || null

  const actualTab = useMemo(() => tab || 'pending', [tab])

  const handleDispatch = () => {
    dispatch(setIsLoading(true))

    const fromDate = selectedDate ? dayjs(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null

    const adjustedToDate = selectedDate ? dayjs(selectedDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss') : null
    setToDate(adjustedToDate)

    refetch()

    return dispatch(
      updateKybNotificationsFilters({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        page,
        id,
        status,
        riskLevel,
        fromDate,
        toDate: adjustedToDate,
      }),
    )
  }

  const apiRefOutdated = useGridApiRef()
  const apiRef = useGridApiRef()

  const today = new Date().setDate(new Date().getDate())
  const formattedDate = dayjs(today).endOf('day').format('YYYY-MM-DDTHH:mm:ss')

  const commonParams = {
    countrySegment,
    limit,
    offset,
    page,
    sortDirection,
    sortField,
  }

  const params = useMemo(
    () => ({
      status: filterStatus,
      ...(tab === 'pending' && { fromDate: formattedDate }),
      ...(toDate && { toDate }),
      ...(riskLevel && { riskLevel }),
    }),
    [filterStatus, tab, formattedDate, toDate, riskLevel],
  )

  const paramsForOutDate = useMemo(
    () => ({
      status: filterStatus,
      toDate: formattedDate,
      sortDirection: sortDirectionOutDate,
      sortField: sortFieldOutDate,
      page: pageOutDate,
      limit: rowsPerPageOutDate,
      offset: rowsPerPageOutDate * pageOutDate,
    }),
    [filterStatus, formattedDate, sortDirectionOutDate, sortFieldOutDate, pageOutDate, rowsPerPageOutDate],
  )

  const { data, refetch } = useGetKybNotificationsQuery({ ...commonParams, ...params }, { skip: tab === 'settings' })
  const { data: dataForOutDate, refetch: refetchOutDate } = useGetKybNotificationsQuery(
    { ...commonParams, ...paramsForOutDate },
    { skip: type === 'next' || tab === 'completed' || tab === 'canceled' || tab === 'settings' },
  )

  const [
    restartKyb,
    { isLoading: restartIsLoading, originalArgs, isError: restartIsError, isSuccess: restartIsSuccess },
  ] = useRestartKybMutation()

  const columns = [
    {
      field: 'name',
      headerName: t('common:name'),
      sortable: false,
      flex: 3,
      renderCell: ({ row }) => (
        <Link
          component={ReactRouterLink}
          title={row.kyb?._id}
          to={`/kyb/${row?.countryCode ?? 'BR'}/detail/${row.kyb?._id}`}
        >
          {row.kyb?.companyDetails?.name}
        </Link>
      ),
    },
    {
      field: 'status',
      headerName: t('common:status'),
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return <TableIcons type={params.row.status.toUpperCase()} />
      },
    },
    {
      field: 'countryCode',
      headerName: t('common:country'),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return params.row.countryCode
      },
    },
    {
      field: 'riskLevel',
      headerName: t('common:riskLevel'),
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return <Box>{params.row.riskLevel ? <RiskLevel type={params.row.riskLevel} /> : ''}</Box>
      },
    },
    {
      field: 'expirationDate',
      headerName: t('common:expirationDate'),
      flex: 1.5,
      renderCell: (params) => {
        const expirationDate = new Date(params.row.expirationDate)
        const today = new Date()

        const timeDifference = expirationDate.getTime() - today.getTime()

        const daysLeft = timeDifference / (1000 * 3600 * 24)

        const isExpiringSoon = daysLeft <= 7

        return (
          <Typography variant="number" style={{ color: isExpiringSoon ? 'red' : 'inherit' }}>
            {toLocaleUTCDateString(params.row.expirationDate)}
          </Typography>
        )
      },
    },
    {
      field: 'action',
      headerName: t('common:actions'),
      headerAlign: 'center',
      sortable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, alignItems: 'center' }}>
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  setActiveNotification(row)
                  setModalOpen(!modalOpen)
                }}
              >
                {t('common:edit')}
              </LoadingButton>
              {row.status === 'PENDING' && (
                <LoadingButton
                  loading={restartIsLoading && originalArgs?.kybId === row.kyb?._id}
                  onClick={() => handleRestart(row.kyb?._id)}
                  variant="outlined"
                >
                  {t('common:reset')}
                </LoadingButton>
              )}
            </Box>
          </Box>
        )
      },
    },
  ]

  const handleRestart = async (kybId) => {
    try {
      await restartKyb({ countrySegment, kybId })
    } catch (error) {
      console.error(error)
    }
  }

  const handleResetButton = () => {
    if (id !== '') setId('')
    if (status) setStatus('')
    if (riskLevel) setRiskLevel('')
    if (toDate) setToDate(null)

    setSelectedDate()
    setPage(0)
    dispatch(setIsLoading(true))

    return dispatch(
      updateKybNotificationsFilters({
        page: 0,
        limit: 25,
        offset: 0,
        id: '',
        status: null,
        riskLevel: null,
        riskLevelOutDate: null,
        expirationDate: '',
        name: '',
        fromDate: null,
        toDate: null,
      }),
    )
  }

  const handleChange = (_, newValue) => {
    if (tab !== 'settings') {
      handleResetButton()
    }
    navigate(`/kyb/notifications/${newValue}`)
  }

  const handleUpdate = (e, data, id) => {
    e.preventDefault()
    setIsFetching(true)
    setFetchMessage('')
    setIsProcessing(true)
    dispatch(setIsLoading(true))

    if (!process.env.REACT_APP_IS_DEMO) {
      const country = getCountrySegment(activeNotification.countryCode)

      let url = `${process.env.REACT_APP_BASEURL}/kyb${country}notification/${id}`

      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      }

      fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsProcessing(false)
            setModalOpen(false)
            setFetchMessage('Update success.')
            setNewStatus('')
            setActiveNotification('')
            dispatch(setIsLoading(false))
            setPage(0)
            refetch()
          } else {
            throw new Error(res.message ? res.message : t('messages:unexpectedError'))
          }
        })
        .catch((err) => {
          console.error('[UPDATE NOTIFICATION ERROR] --> ', err)
          setIsProcessing(false)
          setFetchError(true)
          setModalOpen(false)
          setNewStatus('')
          dispatch(setIsLoading(false))
          setActiveNotification('')
          setFetchMessage(err.message ? err.message : t('messages:unexpectedErrorValues'))
        })
    } else {
      setIsProcessing(false)
      setModalOpen(false)
      setFetchMessage('Update success.')
      setNewStatus('')
      setActiveNotification('')
      dispatch(setIsLoading(false))
      refetch()
    }

    setTimeout(() => {
      setIsFetching(false)
    }, 3000)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  const handleChangePageOutDate = (event, newPage) => {
    setPageOutDate(newPage)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  const handleChangeRowsPerPageOutDate = (event) => {
    setRowsPerPageOutDate(parseInt(event.target.value, 10))
    setPageOutDate(0)
    setIsLoading(true)
  }

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]
      setSortField(field)
      setSortDirection(sort === 'asc' ? 1 : -1)
    } else {
      if (sortField === 'expirationDate') {
        setSortDirection(sortDirection === -1 ? 1 : -1)
      } else {
        setSortField('expirationDate')
        setSortDirection(-1)
      }
      refetch()
    }
    setPage(0)
    setIsLoading(true)
  }

  const handleSortModelChangeOutDate = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]
      setSortFieldOutDate(field)
      setSortDirectionOutDate(sort === 'asc' ? 1 : -1)
    } else {
      if (sortFieldOutDate === 'expirationDate') {
        setSortDirectionOutDate(sortDirectionOutDate === -1 ? 1 : -1)
      } else {
        setSortFieldOutDate('expirationDate')
        setSortDirectionOutDate(-1)
      }
      refetchOutDate()
    }
    setPage(0)
    setIsLoading(true)
  }

  useEffect(() => {
    if (activeNotification) {
      setNewStatus(activeNotification.status)
      setNewExpirationDate(new Date(activeNotification.expirationDate))
    }
  }, [activeNotification])

  useEffect(() => {
    if (isLoading !== isLocallyLoading) dispatch(setIsLoading(isLocallyLoading))
  }, [dispatch, isLoading, isLocallyLoading])

  useEffect(() => {
    if (tab === 'pending' && apiRefOutdated.current) {
      apiRefOutdated.current.autosizeColumns(autosizeOptions)
    }
  }, [apiRefOutdated, tab, theme])

  useEffect(() => {
    if (tab !== 'settings' && apiRef.current) {
      apiRef.current.autosizeColumns(autosizeOptions)
    }
  }, [apiRef, theme, tab])

  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuFilterItem: null,
          columnMenuSortItem: null,
        }}
      />
    )
  }
  const slots = {
    columnMenu: CustomColumnMenu,
  }

  return (
    <Box className={`${isLoading && 'spinner-transition'} filter`}>
      {isLoading && <Spinner noTransform />}
      {isFetching && <AlertFetchSpinner message={fetchMessage} error={fetchError} spinner={isProcessing} />}
      {(restartIsError || restartIsSuccess) && (
        <AlertFetchSpinnerAutoClose
          message={restartIsError ? 'There was an error restarting the KYB' : 'KYB restarted successfully'}
          error={restartIsError}
          spinner={true}
        />
      )}

      <Box className="component-title-wrapper">
        <Box className="component-title" display="flex">
          <Typography variant="h2">{t('messages:kyb.menu.notifications')}</Typography>
        </Box>
        <Typography variant="subtitle3"></Typography>
      </Box>
      <Paper elevation={0}>
        <TabContext value={actualTab}>
          <Box
            className="component-title"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: '1.5rem' }}
          >
            <CustomTabList onChange={handleChange}>
              <Tab label={t('common:pending')} value="pending" />
              <Tab label={t('common:completed')} value="completed" />
              <Tab label={t('common:canceled')} value="canceled" />
              <Tab value="settings" />
            </CustomTabList>
            <Button
              variant="outlined"
              onClick={() => navigate(`/kyb/notifications/settings`)}
              endIcon={<SettingsIcon />}
            >
              <Typography variant="title2">{t('common:settings')}</Typography>
            </Button>
          </Box>
          <TabPanel
            value="pending"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            <Paper elevation={0} variant="colorSecondary" className="kyb-detail">
              <Typography variant="title">{t('common:next')}</Typography>
              <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                <Box className="filter-bar">
                  <Button
                    variant="label"
                    endIcon={<MenuOutlinedIcon />}
                    onClick={() => {
                      setFilterModalOpen(true)
                      setType('next')
                    }}
                  >
                    {t('common:filters')}
                  </Button>
                  <Button
                    variant="label"
                    endIcon={<MenuOutlinedIcon />}
                    onClick={() => apiRef.current.showPreferences('columns')}
                  >
                    {t('common:showHideColumns')}
                  </Button>
                </Box>
                <DataGridPro
                  apiRef={apiRef}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row._id || row.id}
                  rows={data?.data || []}
                  columns={columns}
                  slots={slots}
                  hideFooter={true}
                  autoHeight
                  loading={isLoading}
                  sx={{
                    height: 'calc(100vh - 30rem)',
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'auto',
                    },
                  }}
                  sortingMode="server"
                  sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
                  onSortModelChange={handleSortModelChange}
                />
                <TablePagination
                  labelRowsPerPage={[]}
                  component="div"
                  count={data?.pagination?.totalElements || 0}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Paper>
            {tab === 'pending' && (
              <Paper elevation={0} variant="colorSecondary" className="kyb-detail">
                <Typography variant="title">{t('common:outdated')}</Typography>
                <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                  <DataGridPro
                    apiRef={apiRefOutdated}
                    getRowHeight={() => 'auto'}
                    getRowId={(row) => row._id || row.id}
                    rows={dataForOutDate?.data || []}
                    columns={columns}
                    slots={slots}
                    hideFooter={true}
                    autoHeight
                    loading={isLoading}
                    sx={{
                      height: 'calc(100vh - 30rem)',
                      '& .MuiDataGrid-virtualScroller': {
                        overflow: 'auto',
                      },
                    }}
                    sortingMode="server"
                    sortModel={[{ field: sortFieldOutDate, sort: sortDirectionOutDate === 1 ? 'asc' : 'desc' }]}
                    onSortModelChange={handleSortModelChangeOutDate}
                  />
                  <TablePagination
                    labelRowsPerPage={[]}
                    component="div"
                    count={dataForOutDate?.pagination?.totalElements || 0}
                    page={pageOutDate}
                    onPageChange={handleChangePageOutDate}
                    rowsPerPage={rowsPerPageOutDate}
                    onRowsPerPageChange={handleChangeRowsPerPageOutDate}
                  />
                </Box>
              </Paper>
            )}
          </TabPanel>
          <TabPanel value="completed">
            <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              <Box className="filter-bar">
                <Button
                  variant="label"
                  endIcon={<MenuOutlinedIcon />}
                  onClick={() => {
                    setFilterModalOpen(true)
                    setType('')
                  }}
                >
                  {t('common:filters')}
                </Button>
                <Button
                  variant="label"
                  endIcon={<MenuOutlinedIcon />}
                  onClick={() => apiRef.current.showPreferences('columns')}
                >
                  {t('common:showHideColumns')}
                </Button>
              </Box>
              <DataGridPro
                getRowHeight={() => 'auto'}
                getRowId={(row) => row._id || row.id}
                rows={data?.data || []}
                columns={columns}
                apiRef={apiRef}
                slots={slots}
                hideFooter={true}
                autoHeight
                loading={isLoading}
                sx={{
                  height: 'calc(100vh - 24rem)',
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'auto',
                  },
                }}
                sortingMode="server"
                sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
                onSortModelChange={handleSortModelChange}
              />
              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={data?.pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TabPanel>
          <TabPanel value="canceled">
            <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              <Box className="filter-bar">
                <Button
                  variant="label"
                  endIcon={<MenuOutlinedIcon />}
                  onClick={() => {
                    setFilterModalOpen(true)
                    setType('')
                  }}
                >
                  {t('common:filters')}
                </Button>
                <Button
                  variant="label"
                  endIcon={<MenuOutlinedIcon />}
                  onClick={() => apiRef.current.showPreferences('columns')}
                >
                  {t('common:showHideColumns')}
                </Button>
              </Box>
              <DataGridPro
                getRowHeight={() => 'auto'}
                getRowId={(row) => row._id || row.id}
                rows={data?.data || []}
                columns={columns}
                apiRef={apiRef}
                slots={slots}
                hideFooter={true}
                autoHeight
                loading={isLoading}
                sx={{
                  height: 'calc(100vh - 24rem)',
                  '& .MuiDataGrid-virtualScroller': {
                    overflow: 'auto',
                  },
                }}
                sortingMode="server"
                sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
                onSortModelChange={handleSortModelChange}
              />
              <TablePagination
                labelRowsPerPage={[]}
                component="div"
                count={data?.pagination?.totalElements || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TabPanel>
          <TabPanel value="settings">
            <KybNotificationsSettings
              setIsProcessing={setIsProcessing}
              setFetchError={setFetchError}
              setFetchMessage={setFetchMessage}
              setIsFetching={setIsFetching}
            />
          </TabPanel>
        </TabContext>
      </Paper>

      <FilterModal
        modalOpen={filterModalOpen}
        setModalOpen={setFilterModalOpen}
        handleResetButton={handleResetButton}
        setPage={setPage}
        handleDispatch={handleDispatch}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:to')} {t('common:date')}:
            </Typography>

            <MyDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </Box>

          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:riskLevel')}:
            </Typography>
            <Select
              size="small"
              value={riskLevel}
              fullWidth
              displayEmpty
              onChange={(e) => setRiskLevel(e.target.value)}
            >
              <MenuItem disabled value="">
                <Typography variant="subtitle3">
                  {t('common:select')} {t('common:riskLevel')}
                </Typography>
              </MenuItem>
              {kybRiskLevelEnum.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </FilterModal>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box className="modal">
          {isProcessing ? (
            <ProcessingSpinner message={t('common:processing')} />
          ) : (
            <Box className="send-btn">
              <Box className="modal-top">
                <Box className="modal-titles">
                  <Typography variant="title">
                    {t('common:edit')} {t('common:status')}
                  </Typography>
                </Box>
                <Fab variant="close" onClick={() => setModalOpen(false)}>
                  <CloseOutlinedIcon />
                </Fab>
              </Box>
              <Box>
                <Box className="notifications-row">
                  <p> KYB ID:</p>
                  <Box className="id-action" onClick={() => navigate(`/kyb/detail/${activeNotification?.kyb?._id}`)}>
                    {activeNotification?.kyb?._id}
                  </Box>
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:createdAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.createdAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:updatedAt')}:</p> {buildTimeStringFromTimestamp(activeNotification?.updatedAt)}
                </Box>
                <Box className="notifications-row">
                  <p>{t('common:riskLevel')}:</p>
                  {activeNotification?.riskLevel ? <RiskLevel type={activeNotification?.riskLevel} /> : ''}
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Box className="modal-box">
                  <InputLabel htmlFor="status">{t('common:status')}:</InputLabel>
                  <Select
                    id="status"
                    size="small"
                    fullWidth
                    displayEmpty
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                  >
                    <MenuItem disabled value="">
                      <Typography variant="subtitle3">
                        {t('common:select')} {t('common:status')}
                      </Typography>
                    </MenuItem>
                    {statusEnum.map((item) => (
                      <MenuItem key={item} value={item}>
                        {t(`common:${item}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box className="modal-box">
                  <InputLabel>{t('common:expirationDate')}</InputLabel>
                  <Box className="notifications-row">
                    {buildTimeStringFromTimestamp(activeNotification?.expirationDate)}
                  </Box>
                  <MyDatePicker selectedDate={newExpirationDate} setSelectedDate={setNewExpirationDate} />
                </Box>
              </Box>
              <Box className="modal-filter-buttons">
                <Button size="small" variant="outlinedGrey" onClick={() => setFilterModalOpen(false)}>
                  {t('common:cancel')}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) =>
                    handleUpdate(
                      e,
                      {
                        status: newStatus,
                        expirationDate: new Date(newExpirationDate),
                      },
                      activeNotification?._id,
                    )
                  }
                >
                  {t('common:save')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  )
}

export default KybNotifications
