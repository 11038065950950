import './datatable.scss'
import { useState } from 'react'
import { useParams, Link as ReactRouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { Box, Link, TableContainer, TablePagination, Typography } from '@mui/material'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Flags } from '../flag/flags'
import { TableChip } from '../../utilities/TableChip'
import { TableIcons } from '../../utilities/TableIcons'
import { useFindTransactionsByUserIdQuery } from '../../../features/transactions/transactionSlice'

const DatatableUserTransaction = () => {
  const { t } = useTranslation(['common'])
  const { id } = useParams()
  const { emailClient } = useSelector((state) => state.login)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const {
    data: transactions,
    isFetching,
    isLoading,
  } = useFindTransactionsByUserIdQuery({
    emailClient,
    viewMode: true,
    page,
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    userId: id,
  })

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link
            component={ReactRouterLink}
            to={`/transactions/${params.row.id}`}
            target="_blank"
            style={{ wordBreak: 'break-word' }}
          >
            <Typography variant="number">{params.row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'transactionType',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 170,
      sortable: false,
      renderCell: (params) => {
        return <Box>{params.row.transactionType ? <TableIcons type={params.row.transactionType} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionType
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        return <Box>{params.row.transactionState ? <TableIcons type={params.row.transactionState} /> : '---'}</Box>
      },
      valueGetter: (params) => {
        return params.row.transactionState
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:date'),
      flex: 1,
      minWidth: 134,
      sortable: false,
      valueGetter: (params) => {
        return buildTimeStringFromTimestamp(params.row.createdTimestamp)
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'originMethod',
      headerName: t('common:originMethod'),
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        return <TableChip action={params.row.originMethod} noIcon />
      },
    },
    {
      field: 'originAmount',
      headerName: t('common:originAmount'),
      flex: 1,
      minWidth: 130,
      sortable: false,
      valueGetter: (params) => {
        return params.row.originAmount?.toLocaleString()
      },
    },
    {
      field: 'originCurrency',
      headerName: t('common:originCurrency'),
      flex: 1,
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'originCountry',
      headerName: t('common:originCountry'),
      flex: 1,
      minWidth: 130,
      sortable: false,
      renderCell: (params) => {
        return <Box className="table-body-cell">{Flags(params.row.originCountry, false, true)}</Box>
      },
      valueGetter: (params) => {
        return params.row.originCountry
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
      flex: 1,
      minWidth: 180,
      sortable: false,
    },
    {
      field: 'destinationMethod',
      headerName: t('common:destinationMethod'),
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        return <TableChip action={params.row.destinationMethod} noIcon />
      },
      valueGetter: (params) => {
        return params.row.destinationMethod
      },
    },
    {
      field: 'destinationAmount',
      headerName: t('common:destinationAmount'),
      flex: 1,
      minWidth: 130,
      sortable: false,
      valueGetter: (params) => {
        return params.row.destinationAmount?.toLocaleString()
      },
    },
    {
      field: 'destinationCurrency',
      headerName: t('common:destinationCurrency'),
      flex: 1,
      minWidth: 130,
      sortable: false,
    },
    {
      field: 'destinationCountry',
      headerName: t('common:destinationCountry'),
      flex: 1,
      minWidth: 130,
      sortable: false,
      renderCell: (params) => {
        return <Box>{Flags(params.row.destinationCountry, false, true)}</Box>
      },
      valueGetter: (params) => {
        return params.row.destinationCountry
      },
    },
  ]

  return (
    <TableContainer>
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={transactions?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DataGrid
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(transactions?.data) ? transactions?.data : []}
        columns={columns}
        hideFooter={true}
        autoHeight={true}
        loading={isLoading || isFetching}
      />
    </TableContainer>
  )
}

export default DatatableUserTransaction
