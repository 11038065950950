import { Box, Paper, Typography } from '@mui/material'
import { renderAddresses } from './renderAddresses'
import { Flags } from '../flag/flags'
import '../../../pages/kyt/user/user.scss'
import { renderTags } from './renderTags'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { useTranslation } from 'react-i18next'

export const RenderLegalEntity = ({
  legalEntity,
  mccDetails,
  tags,
  date,
  allowedPaymentMethods,
  acquisitionChannel,
}) => {
  const { t } = useTranslation(['common'])

  return (
    <Box className="user-details-top">
      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:financialDetails')}</Typography>
        <Box className="user-info-3">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:expectedTxsMonth')}</Typography>
            <Typography variant="number">
              {legalEntity?.companyFinancialDetails?.expectedTransactionAmountPerMonth
                ? legalEntity.companyFinancialDetails.expectedTransactionAmountPerMonth.amountValue +
                  ' ' +
                  legalEntity.companyFinancialDetails.expectedTransactionAmountPerMonth.amountCurrency
                : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:expectedTurnoverMonth')}</Typography>
            <Typography variant="number">
              {legalEntity?.companyFinancialDetails?.expectedTurnoverPerMonth
                ? legalEntity.companyFinancialDetails.expectedTurnoverPerMonth?.amountValue +
                  ' ' +
                  legalEntity.companyFinancialDetails.expectedTurnoverPerMonth?.amountCurrency
                : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:createdAt')}</Typography>
            <Typography variant="number">{buildTimeStringFromTimestamp(date)}</Typography>
          </Paper>
        </Box>
      </Box>

      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:companyGeneralDetails')}</Typography>
        <Box className="user-info-3">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:businessIndustry')}</Typography>
            <Typography variant="h5">
              {legalEntity?.companyGeneralDetails?.businessIndustry
                ? legalEntity.companyGeneralDetails.businessIndustry.join(', ')
                : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:legalName')}</Typography>
            <Typography variant="h5">
              {legalEntity?.companyGeneralDetails?.legalName ? legalEntity.companyGeneralDetails.legalName : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:mainProductsServicesSold')}</Typography>
            <Typography variant="h5">
              {legalEntity?.companyGeneralDetails?.mainProductsServicesSold
                ? legalEntity.companyGeneralDetails.mainProductsServicesSold.join(', ')
                : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:createdAt')}</Typography>
            <Typography variant="number">{date ? buildTimeStringFromTimestamp(date) : '---'}</Typography>
          </Paper>
        </Box>
      </Box>

      <Box className="user-details-wrapper">
        <Typography variant="subtitle5">{t('common:companyRegistrationDetails')}</Typography>
        <Box className="user-info-3">
          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:registrationCountry')}</Typography>
            <Typography variant="h5">
              {legalEntity?.companyRegistrationDetails?.registrationCountry
                ? Flags(legalEntity.companyRegistrationDetails.registrationCountry)
                : '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:registrationIdentifier')}</Typography>
            <Typography variant="h5">
              {legalEntity?.companyRegistrationDetails?.registrationIdentifier || '---'}
            </Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:addresses')}</Typography>
            <Typography variant="h5">{renderAddresses(legalEntity)}</Typography>
          </Paper>

          <Paper elevation={0} variant="colorPrimary" className="user-box">
            <Typography variant="subtitle1">{t('common:accountOpeningReason')}</Typography>
            <Typography variant="h5">
              {legalEntity?.reasonForAccountOpening?.length ? legalEntity.reasonForAccountOpening.join(', ') : '---'}
            </Typography>
          </Paper>

          {mccDetails && (
            <Paper elevation={0} variant="colorPrimary" className="user-box">
              <Typography variant="subtitle1">{t('common:mccDetails')}</Typography>
              <Typography variant="h5">
                {t('common:code')}: {mccDetails?.code ? mccDetails?.code : '---'}
              </Typography>
              <Typography variant="h5">
                {t('common:description')}: {mccDetails?.description ? mccDetails?.description : '---'}
              </Typography>
            </Paper>
          )}

          {allowedPaymentMethods?.length > 0 && (
            <Paper elevation={0} variant="colorPrimary" className="user-box">
              <Typography variant="subtitle1">{t('common:allowedPaymentMethods')}</Typography>
              {allowedPaymentMethods.map((pm, index) => {
                return (
                  <Typography variant="h5" key={index}>
                    {pm}
                  </Typography>
                )
              })}
            </Paper>
          )}

          {acquisitionChannel && (
            <Paper elevation={0} variant="colorPrimary" className="user-box">
              <Typography variant="subtitle1">{t('common:acquisitionChannel')}</Typography>
              <Typography variant="h5">{acquisitionChannel}</Typography>
            </Paper>
          )}

          {tags && (
            <Paper elevation={0} variant="colorPrimary" className="user-box">
              <Typography variant="subtitle1">{t('common:tags')}</Typography>
              <Typography variant="h5">{renderTags({ tags })}</Typography>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  )
}
