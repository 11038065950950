import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material'
import { useGetTimelineByCaseQuery } from '../../../../features/cases/caseSlice'
import { KybChip } from '../../../../components/utilities/KybChip'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'

const CaseTimeline = () => {
  const { id } = useParams()
  const { t } = useTranslation(['common', 'messages'])
  const [timelineData, setTimelineData] = useState([])
  const [page, setPage] = useState(0)
  const {
    data: resultsTimeline,
    isLoading: isLoadingTimeline,
    isFetching,
  } = useGetTimelineByCaseQuery({
    id,
    page,
    limit: 10,
  })

  const hasMore = useMemo(() => timelineData.length % 10 === 0 && timelineData.length > 0, [timelineData.length])

  // STYLES
  const getStyles = (type) => {
    let color
    let backgroundColor

    switch (type.toLowerCase()) {
      case 'creation':
        backgroundColor = '#4184c3'
        color = '#fafafa'
        break
      case 'new_transaction':
        backgroundColor = '#0dde4f'
        color = '#494b5a'
        break
      case 'reopen':
        backgroundColor = '#bfb1f2'
        color = '#494b5a'
        break
      case 'report':
        backgroundColor = '#a3efa8'
        color = '#494b5a'
        break
      case 'file':
        backgroundColor = '#f2b1d9'
        color = '#494b5a'
        break
      case 'comment':
        backgroundColor = '#b18cd4'
        color = '#fafafa'
        break
      default:
        backgroundColor = '#fdb72b'
        color = '#494b5a'
        break
    }

    return { color, backgroundColor }
  }

  useEffect(() => {
    if (resultsTimeline?.data?.timeline) {
      setTimelineData((prev) => [...prev, ...resultsTimeline.data.timeline])
    }
  }, [resultsTimeline])

  const handleLoadMore = () => {
    setPage((prev) => prev + 1)
  }

  return (
    <Paper className="kyb-detail-timeline" elevation={0} sx={{ padding: '1rem' }}>
      <Typography variant="h4">{t('common:auditTrail')}</Typography>
      {Array.isArray(timelineData) && (
        <Box className="timeline-wrapper">
          {timelineData.length ? (
            timelineData.map((timeline) => {
              const { color, backgroundColor } = getStyles(timeline.type)
              return (
                <Paper key={timeline._id} className="timeline-card" elevation={0} variant="colorSecondary">
                  <Box className="timeline-box">
                    <Box title={t(`common:${timeline.type}`)}>
                      <KybChip action={t(`common:${timeline.type}`)} color={color} backgroundColor={backgroundColor} />
                    </Box>
                  </Box>
                  <p>{buildTimeStringFromTimestamp(timeline.createdAt)}</p>

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.8rem',
                    }}
                  >
                    <Box
                      className="timeline-box"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <p>{t('common:status')}:</p>
                      <Typography variant="text2">{t(`common:${timeline.status}`)}</Typography>
                    </Box>
                    <Box
                      className="timeline-box"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <p>{t('common:by')}:</p>
                      <Typography variant="text2">{timeline.email}</Typography>
                    </Box>
                    <Box className="timeline-box">
                      <p>{t('common:description')}:</p>
                      <Typography variant="text2">{timeline.description}</Typography>
                    </Box>
                  </Box>
                </Paper>
              )
            })
          ) : (
            <Paper className="timeline-card" elevation={0} variant="colorSecondary">
              <Box className="timeline-box">
                <Box>
                  <KybChip action={t(`common:NO_TIMELINE`)} color={'#494b5a'} backgroundColor={'#fdb72b'} />
                </Box>
              </Box>
              <p>{'---'}</p>

              <Box className="timeline-box">
                <Box className="timeline-box">
                  <p>{t('common:status')}:</p>
                  <Typography variant="text2">---</Typography>
                </Box>
                <Box className="timeline-box">
                  <p>{t('common:operationalAgent')}:</p>
                  <Typography variant="text2">---</Typography>
                </Box>
                <Box className="timeline-box">
                  <p>{t('common:description')}:</p>
                  <Typography variant="text2">{t('common:noTimeline')}</Typography>
                </Box>
              </Box>
            </Paper>
          )}
          {hasMore && (
            <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadMore}
                disabled={isLoadingTimeline || isFetching}
                startIcon={isFetching && <CircularProgress size={20} />}
              >
                {t('common:seeMore')}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Paper>
  )
}

export default CaseTimeline
