import { Box, Button, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { ClearIcon } from '@mui/x-date-pickers'

const searchTypes = ['id', 'userId']

const CaseSearch = ({ defaultValues, handleSearch }) => {
  const { t } = useTranslation(['common', 'messages'])
  const { handleSubmit, control, setValue, watch } = useForm({
    mode: 'onTouched',
    defaultValues,
  })

  const [searchType, searchText] = watch(['searchType', 'searchText'])

  const handleTrimChange = (field, value) => {
    field.onChange(value?.trim())
  }

  const handleKeyDown = (e) => {
    // eslint-disable-next-line
    if (e.keyCode == 13) {
      handleSubmit(handleSearch)()
    }
  }

  const handleClear = () => {
    setValue('searchType', 'id')
    setValue('searchText', '')
    handleSubmit(handleSearch)()
  }

  return (
    <Box>
      <FormControl sx={{ m: 1, width: 320 }}>
        <Controller
          name="searchType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              onChange={field.onChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              renderValue={(value) => (
                <div style={{ display: 'flex', gap: 4 }}>
                  <div>{t(`common:searchBy`)}</div>
                  <div style={{ fontWeight: 'bold' }}>{t(`common:${value}`)}</div>
                </div>
              )}
            >
              {searchTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`common:${item}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: 400 }}>
        <Controller
          name="searchText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              value={field.value}
              onChange={(e) => handleTrimChange(field, e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={t(`messages:compliance.cases.search.placeholder.${searchType}`)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton alt="Clear" disabled={!searchText} onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ m: 1, width: 100 }}>
        <Button
          size="small"
          variant="contained"
          type="submit"
          disabled={!searchText}
          onClick={handleSubmit(handleSearch)}
        >
          {t('common:search')}
        </Button>
      </FormControl>
    </Box>
  )
}

export default CaseSearch
