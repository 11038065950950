import './datatable.scss'
import { useState } from 'react'
import { Box, Button, Chip, Divider, Fab, Link, Modal, Paper, TableContainer, Typography } from '@mui/material'
import { Flags } from '../flag/flags'
import { DataGrid } from '@mui/x-data-grid'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../utilities/TableChip'

const DatatablePaymentDetails = ({ rows }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const { t } = useTranslation(['common'])

  const columns = [
    {
      field: 'id',
      headerName: t('common:method'),
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return <Box className="table-body-cell">{params.row.method ? params.row.method : '---'}</Box>
      },
    },

    {
      field: 'details',
      headerName: t('common:details'),
      flex: 1,
      minWidth: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              setSelected(params.row)
              return setOpen(true)
            }}
          >
            {t('common:viewDetails')}
          </Link>
        )
      },
    },
  ]

  const renderObject = (obj) => {
    if (obj) {
      return Object.entries(obj).map(([key, value]) => {
        if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
          return (
            <Box key={key}>
              {/* <Typography variant="subtitle4">{key}</Typography> */}
              {renderObject(value)}
              <Divider sx={{ marginTop: '1rem' }} />
            </Box>
          )
        } else if (String(value).includes('[object Object]')) {
          return (
            <Box key={key}>
              <h4>{key.charAt(0).toUpperCase() + key.slice(1)}</h4>
              {value.map((element, index) => {
                return <Box key={index}>{renderObject(element)}</Box>
              })}
            </Box>
          )
        } else {
          return (
            <Box
              key={key}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Typography variant="subtitle4">{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>

              <Chip
                sx={{ width: 'fit-content!important' }}
                variant="transparent"
                key={key}
                label={value.toString() === 'true' || value.toString() === 'false' ? value.toString() : value}
              />
            </Box>
          )
        }
      })
    }
    return 'No report'
  }

  return (
    <Box>
      <TableContainer>
        <DataGrid
          getRowId={(row) => row.method}
          className="grid-table"
          rows={Array.isArray(rows) && rows}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
        />
      </TableContainer>
      <Modal
        open={open}
        onClose={() => {
          setSelected('')
          return setOpen(false)
        }}
        className="datatable-modal"
      >
        <Box className="modal payment-details-container">
          <Box className="modal-top">
            <Box className="modal-titles">
              <Typography variant="title">{t('common:savedPaymentDetail')}</Typography>
            </Box>
            <Fab variant="close" onClick={() => setOpen(false)}>
              <CloseOutlinedIcon />
            </Fab>
          </Box>

          <Divider />
          {selected && typeof selected === 'object' ? (
            <Box className="payment-details">
              <Paper elevation={0} variant="colorPrimary" className="payment-details-list">
                <Typography variant="legend">{t('common:information')}:</Typography>

                <Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:method')}:</Typography>
                    <Typography variant="h5">{selected.method ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:paymentChannel')}:</Typography>
                    <Typography variant="h5">{selected.paymentChannel ?? '---'}</Typography>
                  </Box>

                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:transactionReferenceField')}:</Typography>
                    <Typography variant="h5">{selected.transactionReferenceField ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:3dsDone')}:</Typography>
                    <TableChip action={selected['3dsDone'] ? 'yes' : 'no'} noIcon={true} />
                  </Box>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="payment-details-list">
                <Typography variant="legend">{t('common:userDetails')}:</Typography>

                <Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:firstName')}:</Typography>
                    <Typography variant="h5">{selected.nameOnCard?.firstName ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:middleName')}:</Typography>
                    <Typography variant="h5">{selected.nameOnCard?.middleName ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:lastName')}:</Typography>
                    <Typography variant="h5">{selected.nameOnCard?.lastName ?? '---'}</Typography>
                  </Box>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="payment-details-list">
                <Typography variant="legend">{t('common:cardDetails')}:</Typography>

                <Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardExpiry')}:</Typography>
                    <Typography variant="h5">
                      {selected.cardExpiry?.month && selected.cardExpiry?.year
                        ? `${selected.cardExpiry?.month}/${selected.cardExpiry?.year}`
                        : '---'}
                    </Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardType')}:</Typography>
                    <Typography variant="h5">{selected.cardType ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardIssuedCountry')}:</Typography>
                    {selected.cardIssuedCountry ? Flags(selected.cardIssuedCountry) : '---'}
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardBrand')}:</Typography>
                    <Typography variant="h5">{selected.cardBrand ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardLast4Digits')}:</Typography>
                    <Typography variant="h5">{selected.cardLast4Digits ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardFingerprint')}:</Typography>
                    <Typography variant="h5">{selected.cardFingerprint ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardFunding')}:</Typography>
                    <Typography variant="h5">{selected.cardFunding ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:cardAuthenticated')}:</Typography>
                    <TableChip action={selected.cardAuthenticated ? 'yes' : 'no'} noIcon={true} />
                  </Box>
                </Box>
              </Paper>

              <Paper elevation={0} variant="colorPrimary" className="payment-details-list">
                <Typography variant="legend">{t('common:merchantDetails')}:</Typography>

                <Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:id')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.id ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:category')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.category ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:MCC')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.MCC ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:city')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.city ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:country')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.country ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:state')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.state ?? '---'}</Typography>
                  </Box>
                  <Box className="payment-details-box">
                    <Typography variant="subtitle3">{t('common:postCode')}:</Typography>
                    <Typography variant="h5">{selected.merchantDetails?.postCode ?? '---'}</Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Modal>
    </Box>
  )
}

export default DatatablePaymentDetails
