import { Box, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckSujetosObligados = ({ title = '', sujetosObligadosCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  const details = sujetosObligadosCheck?.checkDetails?.results

  return (
    <Box className="detail-group" sx={{ padding: '2rem' }}>
      <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1.5rem' }}>
        {title || t('common:sujetosObligados')}
      </Typography>

      <CheckDetailTitle
        email={sujetosObligadosCheck?.email}
        status={sujetosObligadosCheck?.status}
        createdAt={sujetosObligadosCheck?.createdAt}
        updatedAt={sujetosObligadosCheck?.updatedAt}
      />

      <Typography variant="h4" sx={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
        Resultados
      </Typography>

      {details && details.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.5rem',
          }}
        >
          {details.map((detail, index) => (
            <Box
              key={index}
              sx={{
                padding: '1.5rem',
                border: '0.1rem solid',
                borderColor: 'grey.300',
                borderRadius: '0.8rem',
                minWidth: '25rem',
                maxWidth: '30rem',
                flex: '1',
                backgroundColor: 'grey.50',
              }}
            >
              <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>CUIT:</strong> {detail.cuit || '---'}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Tipo Sujeto:</strong> {detail.tipoSujeto || '---'}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Estado:</strong> {detail.estado || '---'}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Mensaje:</strong> {detail.mensaje || '---'}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                <strong>Fecha Creación:</strong> {new Date(detail.fechaCreacion).toLocaleString() || '---'}
              </Typography>
              <Divider sx={{ marginTop: '1rem' }} />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" sx={{ fontSize: '1rem', marginTop: '1rem' }}>
          {sujetosObligadosCheck?.checkDetails?.info}
        </Typography>
      )}
    </Box>
  )
}

export default CheckSujetosObligados
