import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  AutocompleteAsyncInputForm,
  AutocompleteInputForm,
  DateTimeInputForm,
  TextInputForm,
} from '../../../components/common/form'
import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'
import { CaseStatus } from '../case.status'
import { useLazyFindCasesTagsQuery } from '../../../features/cases/caseSlice'

const FiltersCasesForm = ({ defaultValues = {}, setModalOpen, handleResetButton, handleDispatch, setPage }) => {
  const { t } = useTranslation(['common'])
  const { handleSubmit, reset, control, watch, setValue } = useForm({
    mode: 'onTouched',
    defaultValues,
  })
  const [findCasesTags] = useLazyFindCasesTagsQuery()

  const [fromDate, toDate] = watch(['fromDate', 'toDate'])

  useEffect(() => {
    if (fromDate && !toDate) {
      setValue('toDate', dayjs(fromDate).hour(23).minute(59).second(59))
    } else if (!fromDate && toDate) {
      setValue('fromDate', dayjs(fromDate).hour(0).minute(0).second(0))
    }
  }, [fromDate, toDate, setValue])

  const handleReset = () => {
    reset()
    handleResetButton()
    setModalOpen(false)
  }

  const fetchTags =
    (key = 'uniqueUserKeys') =>
    async () => {
      const { data } = await findCasesTags({}, true)
      if (Array.isArray(data?.data?.[key]) && data.data[key].length) {
        return data.data?.[key][0]
      }

      return []
    }

  return (
    <FilterModalContainer
      setModalOpen={setModalOpen}
      handleResetButton={handleReset}
      setPage={setPage}
      handleDispatch={handleSubmit(handleDispatch)}
    >
      <Box className="modal-box-filters">
        <Box>
          <AutocompleteInputForm
            name="status"
            control={control}
            label={`${t('common:by')} ${t('common:state')}`}
            placeholder={`${t('common:select')} ${t('common:state')}`}
            getOptionLabel={(option) => option?.replace('_', ' ')}
            isOptionEqualToValue={(option, value) => option === value}
            options={CaseStatus}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="fromDate"
            timeName="fromTime"
            control={control}
            label={`${t('common:by')} ${t('common:initialDate')}:`}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="toDate"
            timeName="toTime"
            control={control}
            label={`${t('common:by')} ${t('common:endDate')}:`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="firstName"
            control={control}
            label={`${t('common:by')} ${t('common:firstName')} (${t('common:consumer')}):`}
            placeholder={t('common:firstName')}
          />
        </Box>
        <Box>
          <TextInputForm
            name="legalName"
            control={control}
            label={`${t('common:legalName')} (${t('common:business')}):`}
            placeholder={t('common:legalName')}
          />
        </Box>
        <Box>
          <TextInputForm
            name="documentNumber"
            control={control}
            label={`${t('common:by')} ${t('common:documentNumber')} (${t('common:consumer')}):`}
            placeholder={t('common:documentNumber')}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="userType"
            control={control}
            label={`${t('common:by')} ${t('common:userType')}:`}
            placeholder={`${t('common:select')} ${t('common:userType')}`}
            options={['CONSUMER', 'BUSINESS', 'ALL']}
          />
        </Box>
        <Box>
          <AutocompleteAsyncInputForm
            name="userKey"
            control={control}
            label={`${t('common:by')} ${t('common:tagKey')}:`}
            placeholder={`${t('common:select')} ${t('common:tagKey')}:`}
            queryFn={fetchTags('uniqueUserKeys')}
          />
        </Box>
        <Box>
          <AutocompleteAsyncInputForm
            name="userValue"
            control={control}
            label={`${t('common:by')} ${t('common:tagValue')}:`}
            placeholder={`${t('common:select')} ${t('common:tagValue')}:`}
            queryFn={fetchTags('uniqueUserValues')}
          />
        </Box>
        <Box>
          <AutocompleteAsyncInputForm
            name="transactionKey"
            control={control}
            label={`${t('common:by')} ${t('common:transactionTagKey')}:`}
            placeholder={`${t('common:select')} ${t('common:transactionTagKey')}:`}
            queryFn={fetchTags('uniqueTransactionKeys')}
          />
        </Box>
        <Box>
          <AutocompleteAsyncInputForm
            name="transactionValue"
            control={control}
            label={`${t('common:by')} ${t('common:transactionTagValue')}:`}
            placeholder={`${t('common:select')} ${t('common:transactionTagValue')}:`}
            queryFn={fetchTags('uniqueTransactionValues')}
          />
        </Box>
      </Box>
    </FilterModalContainer>
  )
}

export default FiltersCasesForm
