import { Box, Divider, Fab, Typography } from '@mui/material'
import { camelCaseToPhrase } from '../common/toRender/camelCAseToPhrase'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

const toShow = (key) => {
  return key !== '__v' && key !== '_id'
}

export const renderObject = (obj) => {
  if (obj) {
    return Object.entries(obj).map(([key, value]) => {
      if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        return (
          <Box sx={{ display: 'flex', gap: '.5rem' }} key={key}>
            <Typography variant="number">{camelCaseToPhrase(key)}</Typography>

            {<KeyboardArrowRightOutlinedIcon color="primary" sx={{ height: '1.25rem', width: '1.25rem' }} />}

            {renderObject(value)}
          </Box>
        )
      } else if (String(value).includes('[object Object]')) {
        return (
          <Box key={key}>
            <Divider sx={{ m: '.25rem 0' }} />
            <Typography variant="number">{camelCaseToPhrase(key)}</Typography>
            {value.map((element, index) => {
              return <Box key={index}>{renderObject(element)}</Box>
            })}
          </Box>
        )
      } else {
        return (
          <Box key={key}>
            {toShow(key) && (
              <Box sx={{ display: 'flex', gap: '.5rem', width: '100%', alignItems: 'center' }}>
                <Typography variant="number">{camelCaseToPhrase(key)}:</Typography>
                <Typography variant="title2">
                  {value.toString() === 'true' || value.toString() === 'false' ? value.toString() : value}
                </Typography>
              </Box>
            )}
          </Box>
        )
      }
    })
  }
  return ''
}
