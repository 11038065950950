import './datatable.scss'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, Button, IconButton, Link, TablePagination, Typography } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import { buildTimeStringFromTimestamp } from '../time/timeHelper'
import { Status } from '../../utilities/Status'
import { Flags } from '../flag/flags'
import { setIsLoading } from '../../../features/kyb/kybCountrySlice'
import KybNoRowsOverlay from '../../../pages/kyb/components/KybNoRowsOverlay'
import { useFindKycsQuery } from '../../../services/guenoApi'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'

const DatatableKyc = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setModalOpen,
  creationOnNoRows = false,
  documentNumber = '',
}) => {
  const { t } = useTranslation(['common'])
  const { email } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.kybPerson?.filters)
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)
  const [sortField, setSortField] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState(-1)
  const { isFetching, isLoading, refetch, data } = useFindKycsQuery({
    email: queryState.showMyAssignments ? email : null,
    limit: rowsPerPage,
    sortField,
    sortDirection,
    countrySegment,
    ...queryState,
  })

  const columns = [
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      flex: 3,
      renderCell: ({ row }) => {
        return (
          <Link title={row._id} component={ReactRouterLink} to={`/kyc/${currentCountry}/detail/${row._id}`}>
            <Typography variant="number">{row.documentNumber ?? '---'}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      flex: 6,
      renderCell: ({ row }) => {
        return <p title={row.name ?? '---'}>{row.name ?? '---'}</p>
      },
    },
    {
      field: 'nationality',
      headerName: t('common:nationality'),
      flex: 2,
      renderCell: ({ row }) => {
        return Flags(row.nationality === 'Brazil' ? 'BR' : row.nationality)
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 3,
      renderCell: (params) => {
        return <Status type={params.row.status} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      flex: 3,
      renderCell: ({ row }) => <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>,
    },
  ]

  const slots = {
    columnMenu: CustomColumnMenu,
    loadingOverlay: CustomLoadingOverlay,
  }

  if (creationOnNoRows) {
    slots.noRowsOverlay = KybNoRowsOverlay
  }

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]
      setSortField(field)
      setSortDirection(sort === 'asc' ? 1 : -1)
    } else {
      if (sortField === 'createdAt') {
        setSortDirection(sortDirection === -1 ? 1 : -1)
      } else {
        setSortField('createdAt')
        setSortDirection(-1)
      }
      refetch()
    }
    setPage(0)
    setIsLoading(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            variant="label"
            endIcon={<MenuOutlinedIcon />}
            onClick={() => apiRef.current.showPreferences('columns')}
          >
            {t('common:showHideColumns')}
          </Button>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(data?.data) ? data?.data : []}
        columns={columns}
        apiRef={apiRef}
        slots={slots}
        slotProps={{
          noRowsOverlay: { defaultDocumentNumber: documentNumber, isKyb: false },
        }}
        hideFooter={true}
        autoHeight
        loading={isFetching || isLoading || false}
        sortingMode="server"
        sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
        onSortModelChange={handleSortModelChange}
      />

      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={data?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default DatatableKyc
