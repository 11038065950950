import '../../../kyb/components/pdf.scss'
import html2pdf from 'html2pdf.js'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Fab,
  TableBody,
  Divider,
  ListItem,
  List,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'

const ModalExportReport = ({ caseDetail, userDetail, onClose }) => {
  const { email } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const [caseDetails, setCaseDetails] = useState(true)
  const [transactions, setTransactions] = useState(true)
  const [alerts, setAlerts] = useState(true)
  const [assignedUsers, setAssignedUsers] = useState(true)
  const [generalAlerts, setGeneralAlerts] = useState(false)
  const [timeline, setTimeline] = useState(false)
  const [comments, setComments] = useState(false)
  const [userDetails, setUserDetails] = useState(true)
  const [reports, setReports] = useState(true)
  const [files, setFiles] = useState(true)

  const [transactionsCheck, setTransactionsCheck] = useState([])
  const [alertsCheck, setAlertsCheck] = useState([])
  const [assignedUsersCheck, setAssignedUsersCheck] = useState([])
  const [generalAlertsCheck, setGeneralAlertsCheck] = useState([])
  const [commentsCheck, setCommentsCheck] = useState([])
  const [timelineCheck, setTimelineCheck] = useState([])
  const [reportsCheck, setReportsCheck] = useState([])
  const [filesCheck, setFilesCheck] = useState([])

  useEffect(() => {
    if (Array.isArray(caseDetail?.transactions) && caseDetail?.transactions.length > 0) {
      setTransactionsCheck(caseDetail?.transactions)
      setTransactions(true)
    }

    if (Array.isArray(caseDetail?.alerts) && caseDetail?.alerts.length > 0) {
      setAlertsCheck(caseDetail?.alerts)
      setAlerts(true)
    }

    if (Array.isArray(caseDetail?.assignedUsers) && caseDetail?.assignedUsers.length > 0) {
      setAssignedUsersCheck(caseDetail?.assignedUsers)
      setAssignedUsers(true)
    }

    if (Array.isArray(caseDetail?.generalAlerts) && caseDetail?.generalAlerts.length > 0) {
      setGeneralAlertsCheck(caseDetail?.generalAlerts)
      setGeneralAlerts(true)
    }

    if (Array.isArray(caseDetail?.comments) && caseDetail?.comments.length > 0) {
      setCommentsCheck(caseDetail?.comments)
      setComments(true)
    }

    if (Array.isArray(caseDetail?.timeline) && caseDetail?.comments.length > 0) {
      setTimelineCheck(caseDetail?.timeline)
      setTimeline(true)
    }

    if (Array.isArray(caseDetail?.reports) && caseDetail?.reports.length > 0) {
      setReportsCheck(caseDetail?.reports)
      setReports(true)
    }

    if (Array.isArray(caseDetail?.files) && caseDetail?.files.length > 0) {
      setFilesCheck(caseDetail?.files)
      setFiles(true)
    }

    if (userDetail) {
      setUserDetails(true)
    }
  }, [caseDetail])

  const generatePDF = () => {
    const element = document.getElementById('contentToConvert') // ID of the HTML element
    const options = {
      margin: [20, 15],
      filename: `CASE-${caseDetail?._id}_${new Date().getTime()}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    }
    const today = new Date()
    html2pdf()
      .set(options)
      .from(element)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        let totalPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= totalPages; i++) {
          // set footer to every page
          pdf.setPage(i)
          // set footer font
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          // this example gets internal pageSize just as an example to locate your text near the borders in caseDetail you want to do something like "Page 3 out of 4"
          if (i === 1) {
            pdf.text(
              pdf.internal.pageSize.getWidth() - 580,
              pdf.internal.pageSize.getHeight() - 825,
              `Güeno - PDF Generated on ${buildTimeStringFromTimestamp(today)} by ${email} - ${today.getTime()}`,
            )
          }
          pdf.text(
            pdf.internal.pageSize.getWidth() - 60,
            pdf.internal.pageSize.getHeight() - 30,
            `Page ${i} of ${totalPages}`,
          )
        }
      })
      .save()
  }

  return (
    <Box className="modal">
      <Box className="modal-kyb-head">
        <Button onClick={generatePDF} variant="contained" size="small" color="secondary">
          {t('common:exportToPdf')}
        </Button>

        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="content-to-convert-container">
        <Box className="detail-buttons">
          <Paper variant="rootDashed" sx={{ margin: '0rem .5rem' }}>
            <FormControlLabel
              control={<Checkbox checked={caseDetails} />}
              label={t('common:caseDetails')}
              onChange={() => setCaseDetails(!caseDetails)}
            />
            {userDetail && (
              <FormControlLabel
                control={<Checkbox checked={userDetails} />}
                label={t('common:userDetails')}
                onChange={() => setUserDetails(!userDetails)}
              />
            )}
            {caseDetail?.alerts && (
              <FormControlLabel
                control={<Checkbox checked={alerts} />}
                label={t('common:alerts')}
                onChange={() => setAlerts(!alerts)}
              />
            )}
            {caseDetail?.generalAlerts && (
              <FormControlLabel
                control={<Checkbox checked={generalAlerts} />}
                label={t('common:generalAlerts')}
                onChange={() => setGeneralAlerts(!generalAlerts)}
              />
            )}
            {caseDetail?.transactions && (
              <FormControlLabel
                control={<Checkbox checked={transactions} />}
                label={t('common:transactions')}
                onChange={() => setTransactions(!transactions)}
              />
            )}
            {caseDetail?.assignedUsers && (
              <FormControlLabel
                control={<Checkbox checked={assignedUsers} />}
                label={t('common:assignedUsers')}
                onChange={() => setAssignedUsers(!assignedUsers)}
              />
            )}
            {caseDetail?.comments && (
              <FormControlLabel
                control={<Checkbox checked={comments} />}
                label={t('common:comments')}
                onChange={() => setComments(!comments)}
              />
            )}
            {caseDetail?.timeline && (
              <FormControlLabel
                control={<Checkbox checked={timeline} />}
                label={t('common:timeline')}
                onChange={() => setTimeline(!timeline)}
              />
            )}
            {caseDetail?.reports && (
              <FormControlLabel
                control={<Checkbox checked={reports} />}
                label={t('common:reports')}
                onChange={() => setReports(!reports)}
              />
            )}
            {caseDetail?.files && (
              <FormControlLabel
                control={<Checkbox checked={files} />}
                label={t('common:files')}
                onChange={() => setFiles(!files)}
              />
            )}
          </Paper>
        </Box>
        <Box
          variant="rootOutlined"
          elevation={0}
          sx={{ padding: '.5rem' }}
          id="contentToConvert"
          className="content-to-convert"
        >
          <Typography variant="title">
            {t('common:case')} ID: {caseDetail?._id ?? '---'}
          </Typography>
          {caseDetails && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:details')}</Typography>
              <Box className="detail-group-grid-2">
                <Box>
                  <Typography variant="subtitle1">{t('common:userId')}</Typography>
                  <Typography variant="h5">{caseDetail?.userId ?? '---'}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1">{t('common:status')}</Typography>
                  <Typography variant="h5">{caseDetail?.status ?? '---'}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1">{t('common:label')}</Typography>
                  <Typography variant="h5">{caseDetail?.label ?? '---'}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1">{t('common:creationDate')}</Typography>
                  <Typography variant="h5">{caseDetail?.createdAt ?? '---'}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle1">{t('common:lastUpdated')}</Typography>
                  <Typography variant="h5">{caseDetail?.updatedAt ?? '---'}</Typography>
                </Box>
              </Box>
            </Paper>
          )}

          {alerts && Array.isArray(alertsCheck) && alertsCheck.length > 0 && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:alerts')}:</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('common:transactionId')}</TableCell>
                    <TableCell>{t('common:ruleAction')}</TableCell>
                    <TableCell>{t('common:ruleName')}</TableCell>
                    <TableCell>{t('common:creationDate')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {alertsCheck?.map((alert, alertIdx) => {
                    return (
                      <TableRow key={alertIdx}>
                        <TableCell>{alert?.transactionId ?? '---'}</TableCell>
                        <TableCell>{alert?.ruleAction ?? '---'}</TableCell>
                        <TableCell>{alert?.ruleName ?? '---'}</TableCell>
                        <TableCell>
                          {alert?.createdAt ? buildTimeStringFromTimestamp(alert?.createdAt) : '---'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}

          {generalAlerts && Array.isArray(generalAlertsCheck) && generalAlertsCheck.length > 0 && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:generalAlerts')}:</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{t('common:type')}</TableCell>
                    <TableCell>{t('common:creationDate')}</TableCell>
                    <TableCell>{t('common:updatedAt')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generalAlertsCheck?.map((generalAlert, generalIdx) => {
                    return (
                      <TableRow key={generalIdx}>
                        <TableCell>{generalAlert?._id ?? '---'}</TableCell>
                        <TableCell>{generalAlert?.type ?? '---'}</TableCell>
                        <TableCell>
                          {generalAlert?.createdAt ? buildTimeStringFromTimestamp(generalAlert?.createdAt) : '---'}
                        </TableCell>
                        <TableCell>
                          {generalAlert?.updatedAt ? buildTimeStringFromTimestamp(generalAlert?.updatedAt) : '---'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}

          {transactions && Array.isArray(transactionsCheck) && transactionsCheck.length > 0 && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:transactions')}:</Typography>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>{t('common:transactionId')}</TableCell>
                    <TableCell>{t('common:transactionState')}</TableCell>
                    <TableCell>{t('common:destinationUserId')}</TableCell>
                    <TableCell>{t('common:creationDate')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionsCheck?.map((transaction, indexTx) => {
                    return (
                      <TableRow key={indexTx}>
                        <TableCell>{transaction?._id ?? '---'}</TableCell>
                        <TableCell>{transaction?.transactionId ?? '---'}</TableCell>
                        <TableCell>{transaction?.transactionState ?? '---'}</TableCell>
                        <TableCell>{transaction?.destinationUserId ?? '---'}</TableCell>
                        <TableCell>
                          {transaction?.timestamp ? new Date(transaction?.timestamp).toLocaleDateString() : '---'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Paper>
          )}

          {userDetails && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:userDetails')}</Typography>
              {userDetail && Object.keys(userDetail)?.length > 0 && (
                <Box className="detail-group-grid-3">
                  <Box>
                    <Typography variant="subtitle1">ID</Typography>
                    <Typography variant="h5">{userDetail?._id ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:userId')}</Typography>
                    <Typography variant="h5">{userDetail?.userId ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:type')}</Typography>
                    <Typography variant="h5">{userDetail?.type ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:gender')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.gender ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:firstName')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.name?.firstName ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:middleName')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.name?.middleName ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:lastName')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.name?.lastName ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:countryOfNationality')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.countryOfNationality ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:countryOfResidence')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.countryOfResidence ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:dateOfBirth')}</Typography>
                    <Typography variant="h5">{userDetail?.userDetails?.dateOfBirth ?? '---'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">{t('common:email')}</Typography>
                    <Typography variant="h5">{userDetail?.email}</Typography>
                  </Box>
                </Box>
              )}

              {Array.isArray(userDetail?.legalDocuments) && userDetail?.legalDocuments?.length > 0 && (
                <Box>
                  <Divider sx={{ margin: '1rem 0rem' }} />
                  <Typography variant="title2">{t('common:legalDocuments')}:</Typography>
                  {userDetail?.legalDocuments?.map((ld, idx) => {
                    return (
                      <Box key={idx} className="detail-group-grid-3">
                        <Box>
                          <Typography variant="subtitle1">{t('common:issuedCountry')}</Typography>
                          <Typography variant="h5">{ld.documentIssuedCountry ?? '---'}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle1">{t('common:documentNumber')}</Typography>
                          <Typography variant="h5">{ld.documentNumber ?? '---'}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="subtitle1">{t('common:type')}</Typography>
                          <Typography variant="h5">{ld.documentType ?? '---'}</Typography>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
              )}

              {userDetail?.contactDetails?.addresses &&
                Array.isArray(userDetail?.contactDetails?.addresses) &&
                userDetail?.contactDetails?.addresses.length > 0 && (
                  <Box>
                    <Divider sx={{ margin: '1rem 0rem' }} />
                    <Typography variant="title2">{t('common:addresses')}:</Typography>

                    {userDetail?.contactDetails?.addresses?.map((element, index) => {
                      return (
                        <Box className="detail-group-grid-4" key={index}>
                          {Object.keys(element).map((item, index) => {
                            return (
                              item !== 'tags' && (
                                <Box key={index} className="subsection-item-title">
                                  <Typography variant="subtitle1">{t(`common:${item}`)}:</Typography>{' '}
                                  <Typography variant="h5">{element[item]}</Typography>
                                </Box>
                              )
                            )
                          })}
                        </Box>
                      )
                    })}
                  </Box>
                )}

              {Array.isArray(userDetail?.tags) && userDetail?.tags?.length > 0 && (
                <Box>
                  <Divider sx={{ margin: '1rem 0rem' }} />
                  <Typography variant="title2">{t('common:tags')}:</Typography>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('common:key')}</TableCell>
                        <TableCell>{t('common:value')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userDetail?.tags?.map((tag, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{tag.key}</TableCell>
                            <TableCell>{tag.value}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Paper>
          )}

          {assignedUsers && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:assignedUsers')}</Typography>

              {Array.isArray(assignedUsersCheck) &&
                assignedUsersCheck?.length > 0 &&
                assignedUsersCheck?.map((element, index) => {
                  return (
                    <Box key={index} sx={{ marginLeft: '1rem' }}>
                      <List sx={{ listStyleType: 'disc', paddingTop: '0', paddingBottom: '0' }}>
                        <ListItem sx={{ display: 'list-item' }}>{element?.email}</ListItem>
                      </List>
                    </Box>
                  )
                })}
            </Paper>
          )}

          {reports && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:reports')}</Typography>
              {reportsCheck &&
                Array.isArray(reportsCheck) &&
                reportsCheck?.length > 0 &&
                reportsCheck?.map((element, index) => {
                  return (
                    <Box className="detail-flex-column" sx={{ marginBottom: '1.5rem' }} key={index}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('common:by')}</TableCell>
                            <TableCell>{t('common:userId')}</TableCell>
                            <TableCell>{t('common:status')}</TableCell>
                            <TableCell>{t('common:createdAt')}</TableCell>
                            <TableCell>{t('common:updatedAt')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{element.email}</TableCell>
                            <TableCell>{element.userId}</TableCell>
                            <TableCell>{element.closed ? t('common:CLOSED') : t('common:OPEN')}</TableCell>
                            <TableCell>
                              {element.createdAt ? buildTimeStringFromTimestamp(element.createdAt) : '---'}
                            </TableCell>
                            <TableCell>
                              {element.updatedAt ? buildTimeStringFromTimestamp(element.updatedAt) : '---'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('common:description')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Box
                                className="report-pdf-wrapper"
                                dangerouslySetInnerHTML={{ __html: element.description }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  )
                })}
            </Paper>
          )}

          {files && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:files')}</Typography>
              {filesCheck &&
                Array.isArray(filesCheck) &&
                filesCheck?.length > 0 &&
                filesCheck?.map((element, index) => {
                  const splittedName = element.split('/case/')[1].split('_')
                  const fileName = splittedName[splittedName.length - 2]?.replaceAll('%20', ' ')
                  return (
                    <Table key={index}>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('common:name')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{fileName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )
                })}
            </Paper>
          )}

          {timeline && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:timeline')}</Typography>
              {timelineCheck && (
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('common:email')}</TableCell>
                        <TableCell>{t('common:type')}</TableCell>
                        <TableCell>{t('common:description')}</TableCell>
                        <TableCell>{t('common:status')}</TableCell>
                        <TableCell>{t('common:date')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(timelineCheck) &&
                        timelineCheck?.length > 0 &&
                        timelineCheck?.map((element, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{element.email ?? '---'}</TableCell>
                              <TableCell>{element.type ?? '---'}</TableCell>
                              <TableCell>{element.description ?? '---'}</TableCell>
                              <TableCell>{element.status ?? '---'}</TableCell>
                              <TableCell>
                                {element.createdAt ? buildTimeStringFromTimestamp(element.createdAt) : '---'}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Paper>
          )}

          {comments && (
            <Paper variant="rootOutlined" className="detail-group">
              <Typography variant="title">{t('common:comments')}</Typography>
              {commentsCheck && (
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('common:email')}</TableCell>
                        <TableCell>{t('common:description')}</TableCell>
                        <TableCell>{t('common:date')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(commentsCheck) &&
                        commentsCheck?.length > 0 &&
                        commentsCheck?.map((element, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{element.email ?? '---'}</TableCell>
                              <TableCell>{element.description ?? '---'}</TableCell>
                              <TableCell>
                                {element.createdAt ? buildTimeStringFromTimestamp(element.createdAt) : '---'}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ModalExportReport
