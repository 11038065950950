import { Box, Typography, Card, CardContent, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckChequesRechazados = ({ title = '', chequesRechazadosCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  const details = chequesRechazadosCheck?.checkDetails?.results

  return (
    <Box className="detail-group">
      <Typography variant={variantByLevel[level] || variantByLevel[0]}>
        {title || t('common:chequesRechazados')}
      </Typography>

      <CheckDetailTitle
        email={chequesRechazadosCheck?.email}
        status={chequesRechazadosCheck?.status}
        createdAt={chequesRechazadosCheck?.createdAt}
        updatedAt={chequesRechazadosCheck?.updatedAt}
      />

      {details && (
        <Box mt={2}>
          <Typography variant="body1">
            <strong>Identificación:</strong> {details.identificacion || '---'}
          </Typography>
          <Typography variant="body1">
            <strong>Denominación:</strong> {details.denominacion || '---'}
          </Typography>
        </Box>
      )}

      {details?.causales?.length > 0 ? (
        <Box mt={2}>
          <Typography variant="h4">Causales</Typography>
          {details.causales.map((causal, index) => (
            <Card key={index} variant="outlined">
              <CardContent>
                <Typography variant="body1">
                  <strong>Causal:</strong> {causal.causal || '---'}
                </Typography>
                <Typography variant="body1">
                  <strong>Entidad:</strong> {causal.entidades?.map((entidad) => entidad.entidad || '---').join(', ')}
                </Typography>
                <Divider sx={{ my: 2 }} />

                {causal.entidades?.map((entidad, _) =>
                  entidad.detalle.map((detalle, indexDetalle) => (
                    <Box key={indexDetalle}>
                      <Typography>
                        <strong>Número cheque:</strong> {detalle.nroCheque ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Fecha rechazo:</strong> {detalle.fechaRechazo ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Monto:</strong> {detalle.monto ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Fecha pago:</strong> {detalle.fechaPago ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Fecha pago multa:</strong> {detalle.fechaPagoMulta ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Estado multa:</strong> {detalle.estadoMulta ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>Cuenta personal:</strong> {detalle.ctaPersonal ? 'SI' : '---'}
                      </Typography>
                      <Typography>
                        <strong>Denominación jurídica:</strong> {detalle.denomJuridica ?? '---'}
                      </Typography>
                      <Typography>
                        <strong>En revisión:</strong> {detalle.enRevision ? 'Sí' : 'No'}
                      </Typography>
                      <Typography>
                        <strong>Proceso judicial:</strong> {detalle.procesoJud ? 'Sí' : 'No'}
                      </Typography>
                    </Box>
                  )),
                )}
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography variant="body1">{chequesRechazadosCheck?.checkDetails?.info}</Typography>
      )}
    </Box>
  )
}

export default CheckChequesRechazados
