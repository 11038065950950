import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import RichTextEditor from '../RichTextEditor'

const SectionCreateReport = ({
  defaultValue = '',
  onCloseReport,
  onCreateReport,
  onUpdateReport,
  hasPermissions,
  lastReport,
  isAssigned,
  caseIsClosed,
}) => {
  const { activeServices } = useSelector((state) => state.login)
  const { t } = useTranslation(['common', 'messages'])
  const [report, setReport] = useState(defaultValue)

  return (
    <Box className="kyb-info">
      <Typography variant="title">{t('common:report')}</Typography>
      <Box>
        <RichTextEditor
          value={report}
          onChange={setReport}
          disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
        />
      </Box>
      <Box className="reports-row">
        {lastReport && (
          <Button
            variant="outlinedGrey"
            size="small"
            disabled={(!hasPermissions && !isAssigned) || caseIsClosed}
            onClick={() => {
              onCloseReport({ report })
              setReport('')
            }}
          >
            {t('common:close')} {t('common:report')}
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          disabled={
            (!hasPermissions && !isAssigned) ||
            caseIsClosed ||
            (!activeServices.includes('caseManagement:idreports') && !!activeServices.includes('caseReports:id'))
          }
          size="small"
          onClick={() => {
            !lastReport ? onCreateReport({ report }) : onUpdateReport({ report })
          }}
        >
          {!lastReport ? `${t('common:create')}` : `${t('common:update')}`} {t('common:report')}
        </Button>
      </Box>
    </Box>
  )
}

export default SectionCreateReport
