import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link as ReactRouterLink } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Modal,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  resetCasesFilters,
  toogleShowMyCases,
  updateCasesFilters,
  useFindCasesQuery,
  useUpdateCaseCommentMutation,
  useUpdateCaseMutation,
} from '../../../features/cases/caseSlice'
import { useLazyGetKytUserByIdQuery } from '../../../features/transactions/transactionSlice'
import CustomColumnMenu from '../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../components/common/datagrid/CustomLoadingOverlay'
import { TableChip } from '../../../components/utilities/TableChip'
import {
  buildTimeStringFromTimestamp,
  formatDate,
  toLocaleUTCDateString,
} from '../../../components/common/time/timeHelper'
import FiltersCasesForm from './FiltersCasesForm'
import EditCaseForm from './EditCaseForm'
import toaster from '../../../toaster'
import CaseSearch from './components/CaseSearch'

const defaultSortModel = { field: 'updatedAt', sort: 'desc' }

const CasesPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'messages'])
  const navigate = useNavigate()
  const { emailClient, email, roles } = useSelector((state) => state.login)
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [caseToEdit, setCaseToEdit] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const [updateCase, { isUpdatingCase }] = useUpdateCaseMutation()
  const [updateCaseComment, { isLoading: isUpdatingCaseComment }] = useUpdateCaseCommentMutation()
  const [getKytUserById] = useLazyGetKytUserByIdQuery()

  const queryState = useSelector((state) => state.case.filters)
  const {
    data: allCases,
    isLoading: isLoadingCases,
    isFetching: isFetchingCases,
    refetch,
  } = useFindCasesQuery({
    ...queryState,
    emailClient,
    page,
    limit: rowsPerPage,
    offset: rowsPerPage * page,
    roles,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })
  const defaultFromDate = queryState.fromDate ? dayjs(queryState.fromDate) : null
  const defaultToDate = queryState.toDate ? dayjs(queryState.toDate) : null

  const apiRef = useGridApiRef()

  const handleResetButton = () => {
    setPage(0)
    dispatch(resetCasesFilters())
  }

  const handleToogleShowMyCases = () => {
    dispatch(toogleShowMyCases())
  }

  const handleSearch = (values) => {
    return dispatch(
      updateCasesFilters({
        viewMode: true,
        page: 0,
        limit: rowsPerPage,
        offSet: rowsPerPage * page,
        ...queryState,
        searchText: values.searchText,
        searchType: values.searchType,
      }),
    )
  }

  const handleDispatch = (values) => {
    const { fromDate, toDate, ...filters } = values
    const formattedFromDate = fromDate ? formatDate(fromDate) : null
    const formattedToDate = toDate ? formatDate(toDate) : null
    return dispatch(
      updateCasesFilters({
        viewMode: true,
        page,
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        isMyCases: queryState.isMyCases,
        searchText: queryState.searchText,
        searchType: queryState.searchType,
        ...filters,
      }),
    )
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  const handleGoToUser = async (userId) => {
    const { data: userData, isSuccess } = await getKytUserById({ userId, emailClient })
    if (isSuccess && userData.data) {
      navigate(`/users/${userId}?type=${userData.data.type}`)
    }
  }

  const handleUpdateCase = async (values) => {
    const { comment, status, label, category } = values
    const bodyUpdateCaseComment = {
      description: comment !== '' ? comment : 'Closed without comment.',
      emailClient,
      email,
    }

    const bodyUpdateCase = {
      emailClient,
      ...(status && { status }),
      ...(label && { label }),
      ...(category && { category }),
    }

    try {
      await Promise.all([
        updateCaseComment({ body: bodyUpdateCaseComment, caseToClose: caseToEdit }).unwrap(),
        updateCase({ body: bodyUpdateCase, caseToClose: caseToEdit }).unwrap(),
      ])
      setCaseToEdit()
      refetch()
      toaster.success(t('messages:updateSuccess'))
    } catch (error) {
      toaster.error(t('messages:updateError'))
      console.error(error?.data?.data)
    }
  }

  // const handleUpdateSelected = async (item, added) => {
  //   dispatch(updateSelectedCases({ item, added }))
  // }

  const columns = [
    // {
    //   field: 'select',
    //   headerName: '',
    //   width: 60,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       <Checkbox
    //         checked={!!selectedCases[row.id]}
    //         onChange={(e) => handleUpdateSelected({ maskId: row.maskId ?? '', id: row.id }, e.target.checked)}
    //       />
    //     )
    //   },
    // },
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 140,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Link
            component={ReactRouterLink}
            to={`/case-management/case/${row.id}`}
            title={row.maskId ? row.maskId : row.id}
            style={{ wordBreak: 'break-word' }}
          >
            <Typography variant="number">{row.maskId || row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'userId',
      headerName: t('common:userId'),
      minWidth: 140,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Link onClick={() => handleGoToUser(row.userId)} style={{ wordBreak: 'break-word' }}>
            <Typography variant="number">{row.userId}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      minWidth: 200,
      sortable: false,
      renderCell: ({ row }) => {
        return row.name ?? '---'
      },
    },
    {
      field: 'transactions',
      headerName: t('common:transactions'),
      minWidth: 100,
      sortable: true,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.transactions || 0}</Typography>
      },
    },
    {
      field: 'generalAlerts',
      headerName: t('common:alerts'),
      minWidth: 100,
      sortable: true,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.generalAlerts || 0}</Typography>
      },
    },
    {
      field: 'uniqueAlertCase',
      headerName: t('common:uniqueAlertCase'),
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <TableChip noIcon={true} action={row.uniqueAlertCase ? 'yes' : 'no'} />
      },
    },
    {
      field: 'status',
      headerName: t('common:caseStatus'),
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return <TableChip noIcon={true} action={row.status} />
      },
    },
    {
      field: 'category',
      headerName: t('common:category'),
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'label',
      headerName: t('common:label'),
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'assignedRoles',
      headerName: t('common:assignedRoles'),
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Box className="cellOverflow">
            {row.assignedRoles?.length ? row.assignedRoles.map((item) => item.roleName).join(', ') : '---'}
          </Box>
        )
      },
    },
    {
      field: 'checklistInstanceIds',
      headerName: t('common:checklists'),
      minWidth: 150,
      sortable: true,
      renderCell: ({ row }) => {
        return <Typography variant="number">{row.checklistInstanceIds || 0}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      minWidth: 200,
      sortable: true,
      renderCell: ({ row }) => {
        return <Typography variant="number">{toLocaleUTCDateString(row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      minWidth: 200,
      sortable: true,
      renderCell: ({ row }) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(row.updatedAt)}</Typography>
      },
    },
    {
      field: 'actions',
      headerName: t('common:actions'),
      sortable: false,
      filterable: false,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <Link
            size="small"
            onClick={() => {
              setCaseToEdit({ maskId: row.maskId ?? '', id: row.id })
            }}
          >
            {t('common:update')}
          </Link>
        )
      },
    },
  ]

  return (
    <Box className="filter">
      <Box className="component-title-wrapper">
        <Box className="component-title">
          <Typography variant="h2">{t(`messages:compliance.menu.cases`)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <CaseSearch
            defaultValues={{
              searchType: queryState.searchType,
              searchText: queryState.searchText,
            }}
            handleSearch={handleSearch}
          />
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                checked={queryState.isMyCases}
                onChange={handleToogleShowMyCases}
              />
            }
            label={'My Cases'}
          />
        </Box>
      </Box>
      <Paper elevation={0}>
        <Box style={{ width: '100%', height: '100%' }}>
          <Box className="filter-bar">
            <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setFiltersOpen(true)}>
              {t('common:filters')}
            </Button>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={allCases?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Button
                variant="label"
                endIcon={<MenuOutlinedIcon />}
                onClick={() => apiRef.current.showPreferences('columns')}
              >
                {t('common:showHideColumns')}
              </Button>
              <IconButton onClick={refetch} disabled={isLoadingCases || isFetchingCases} title={t('common:refresh')}>
                <RefreshIcon color="action" />
              </IconButton>
            </Box>
          </Box>
          <DataGridPro
            getRowHeight={() => 'auto'}
            getRowId={(row) => row._id || row.id}
            rows={Array.isArray(allCases?.data) ? allCases.data : []}
            columns={columns}
            apiRef={apiRef}
            slots={{
              columnMenu: CustomColumnMenu,
              loadingOverlay: CustomLoadingOverlay,
            }}
            hideFooter={true}
            autoHeight
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            loading={isLoadingCases || isFetchingCases}
          />
        </Box>
      </Paper>

      <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        <div>
          <FiltersCasesForm
            defaultValues={{
              caseId: queryState.caseId,
              status: queryState.status,
              fromDate: defaultFromDate || null,
              fromTime: defaultFromDate ? defaultFromDate.format('HH:mm') : '00:00',
              toDate: defaultToDate || null,
              toTime: defaultToDate ? defaultToDate.format('HH:mm') : '23:59',
              userId: queryState.userId,
              firstName: queryState.firstName,
              legalName: queryState.legalName,
              documentNumber: queryState.documentNumber,
              userType: queryState.userType,
              userKey: queryState.userKey,
              userValue: queryState.userValue,
              transactionKey: queryState.transactionKey,
              transactionValue: queryState.transactionValue,
            }}
            setModalOpen={setFiltersOpen}
            handleResetButton={handleResetButton}
            handleDispatch={handleDispatch}
            setPage={setPage}
          />
        </div>
      </Modal>

      <Modal open={!!caseToEdit} onClose={() => setCaseToEdit()}>
        <div>
          <EditCaseForm
            setModalOpen={setCaseToEdit}
            caseToClose={caseToEdit}
            onSubmit={handleUpdateCase}
            isLoading={isUpdatingCase || isUpdatingCaseComment}
          />
        </div>
      </Modal>
    </Box>
  )
}

export default CasesPage
