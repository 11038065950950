import { Box, Fab, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'

const ModalViewReport = ({ onClose, report }) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">
            {t('common:report')} {report.updatedAt ? buildTimeStringFromTimestamp(report.updatedAt) : '---'}
          </Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="modal-box-filters">
        <Box dangerouslySetInnerHTML={{ __html: report.description }} />
      </Box>
    </Box>
  )
}

export default ModalViewReport
