import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { useTranslation } from 'react-i18next'
import RiskMatrixEvaluatedData from './RiskMatrixEvaluatedData'
import RiskMatrixValueData from './RiskMatrixValueData'

const RiskMatrixResult = ({ riskResult, country = 'BR' }) => {
  const showDataDetail = false
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        width: '80vw',
        height: '70vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {riskResult?.results && (
        <Box>
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid xs={1}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.category')}</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.attribute')}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.valueFound')}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.operator')}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.value')}</Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.isHit')}</Typography>
            </Grid>
            <Grid xs={1} textAlign="center">
              <Typography fontWeight="bold">{t('messages:riskmatrix.title.score')}</Typography>
            </Grid>
          </Grid>
          {riskResult?.results.map(
            ({ category, attribute, operator, operatorFound, value, score, dataToEvaluate, isHit, list }) => {
              const key = `${category}-${attribute}-${operator}-${
                typeof value === 'string' ? value : JSON.stringify(value)
              }`
              const dataToEvaluateNotFound = dataToEvaluate === null || dataToEvaluate === undefined
              let highlights = []
              if (operator === 'any_includes_any' && Array.isArray(value)) {
                highlights = value
              } else if (operator === 'any_includes' && typeof value === 'string') {
                highlights = [value]
              }
              return (
                <Grid
                  container
                  key={key}
                  rowSpacing={1}
                  columnSpacing={2}
                  sx={{ backgroundColor: dataToEvaluateNotFound ? 'lightpink' : isHit ? 'lightyellow' : 'unset' }}
                >
                  <Grid xs={1}>
                    <Typography>{category}</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Typography>{t(`messages:riskmatrix.attribute.${country}.${attribute}`)}</Typography>
                  </Grid>
                  <Grid xs={2}>
                    {dataToEvaluateNotFound ? (
                      <Typography>{t(`messages:riskmatrix.errors.dataToEvaluateNotFound`)}</Typography>
                    ) : (
                      <RiskMatrixEvaluatedData data={dataToEvaluate} highlights={highlights} />
                    )}
                  </Grid>
                  <Grid xs={2}>
                    <Typography>{t(`messages:riskmatrix.operator.${operator}`)}</Typography>
                  </Grid>
                  <Grid xs={2}>
                    <RiskMatrixValueData data={value} list={operator === 'in_custom_list' ? list : undefined} />
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    {!operatorFound ? (
                      <Typography>Error</Typography>
                    ) : (
                      <Typography>{t(`common:${isHit ? 'yes' : 'no'}`)}</Typography>
                    )}
                  </Grid>
                  <Grid xs={1} textAlign="center">
                    <Typography>{score}</Typography>
                  </Grid>
                </Grid>
              )
            },
          )}
        </Box>
      )}

      {showDataDetail && riskResult?.data && (
        <Box>
          <Typography variant="h4">KYB data evaluated</Typography>
          {Object.keys(riskResult?.data).map((key) => {
            const data = riskResult?.data?.[key]
            if (key === 'categories') {
              return (
                <Box key={key} sx={{ marginTop: '0.5rem' }}>
                  <Box>
                    <Typography>{t(`common:${key}`)}</Typography>
                  </Box>
                  <Box>
                    {data?.map((item) => (
                      <Typography key={`${item.secao} ${item.descricao}`}>
                        {item.secao} {item.descricao} ({item.classe})
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )
            }

            if (key === 'relationships') {
              return (
                <Box key={key} sx={{ marginTop: '0.5rem' }}>
                  <Box>
                    <Typography>{t(`common:${key}`)}</Typography>
                  </Box>
                  <Box>
                    {data?.map((item) => (
                      <Typography key={`${item.type} ${item.documentNumber}`}>
                        {item.type} {item.documentNumber} {item.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              )
            }

            let valueToShow = ''

            if (key === 'porte') {
              valueToShow = `${data?.id} ${data?.descricao}`
            } else if (key === 'addresses') {
              valueToShow = data
                ?.map(
                  (item) =>
                    `${item.street} ${item.number} ${item.apt_suite}, ${item.postcode}, ${item.city}, ${item.state}, ${item.country}`,
                )
                .join(' | ')
            } else if (key === 'naturezaJuridica') {
              valueToShow = `${data?.codigo} ${data?.descricao}`
            } else if (key === 'kyc') {
              valueToShow = data?.informação
            } else if (key === 'lastBeneficiaries') {
              valueToShow = data?.join(', ')
            } else if (typeof data === 'string') {
              valueToShow = data
            }

            return (
              <Box key={key} sx={{ marginTop: '0.5rem' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Typography>{t(`common:${key}`)}: </Typography>
                  <Typography>{valueToShow || 'No data found'}</Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

export default RiskMatrixResult
