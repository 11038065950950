import { Box, Typography } from '@mui/material'
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined'
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined'
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined'
import './status.scss'
import { useTranslation } from 'react-i18next'

export const StatusIndicator = ({ type, color }) => {
  const { t } = useTranslation(['common'])
  const boxStyle = { display: 'flex', alignItems: 'center', gap: '.5rem' }
  const iconStyle = { color: color || '#66766B', width: '1.75rem', height: '1.75rem' }

  switch (type) {
    case 'DENIED':
      return (
        <Box sx={boxStyle}>
          <RemoveCircleOutlineOutlinedIcon sx={iconStyle} />
          <Typography variant="h4" color={color}>
            {t('common:denied')}
          </Typography>
        </Box>
      )
    case 'NOT_STARTED':
      return (
        <Box sx={boxStyle}>
          <MoreTimeOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:notStarted')}</Typography>
        </Box>
      )
    case 'IN_PROGRESS':
      return (
        <Box sx={boxStyle}>
          <RotateRightOutlinedIcon sx={iconStyle} />
          <Typography variant="h4" color={color}>
            {t('common:inProgress')}
          </Typography>
        </Box>
      )
    case 'APPROVED':
      return (
        <Box sx={boxStyle}>
          <TaskAltOutlinedIcon sx={iconStyle} />
          <Typography variant="h4" color={color}>
            {t('common:approved')}
          </Typography>
        </Box>
      )
    case 'PROCESSED':
      return (
        <Box sx={boxStyle}>
          <TaskAltOutlinedIcon sx={iconStyle} />
          <Typography variant="h4" color={color}>
            {t('common:processed')}
          </Typography>
        </Box>
      )
    case 'PENDING':
      return (
        <Box sx={boxStyle}>
          <AccessTimeOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:pending')}</Typography>
        </Box>
      )
    case 'CANCELED':
      return (
        <Box sx={boxStyle}>
          <HighlightOffOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:canceled')}</Typography>
        </Box>
      )
    case 'COMPLETED':
      return (
        <Box sx={boxStyle}>
          <MoreTimeOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:completed')}</Typography>
        </Box>
      )
    case 'ACTIVE':
      return (
        <Box sx={boxStyle}>
          <TaskAltOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:active')}</Typography>
        </Box>
      )
    case 'BLOCKED':
      return (
        <Box sx={boxStyle}>
          <RemoveCircleOutlineOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:blocked')}</Typography>
        </Box>
      )
    case 'CREATED':
      return (
        <Box sx={boxStyle}>
          <AddCircleOutlineOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:created')}</Typography>
        </Box>
      )
    case 'DORMANT':
      return (
        <Box sx={boxStyle}>
          <HistoryToggleOffOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:dormant')}</Typography>
        </Box>
      )
    case 'SUSPENDED':
      return (
        <Box sx={boxStyle}>
          <PauseCircleOutlineOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:suspended')}</Typography>
        </Box>
      )
    case 'TERMINATED':
      return (
        <Box sx={boxStyle}>
          <DataSaverOffOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:terminated')}</Typography>
        </Box>
      )
    case 'UNACCEPTABLE':
      return (
        <Box sx={boxStyle}>
          <HighlightOffOutlinedIcon sx={iconStyle} />
          <Typography variant="h4">{t('common:unacceptable')}</Typography>
        </Box>
      )
    default:
      return
  }
}
