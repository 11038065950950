import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

export const riskMatrixSlice = createSlice({
  name: 'riskMatrix',
  initialState: {
    riskMatrixs: [],
    filters: {
      name: '',
      status: '',
      viewMode: true,
      page: 0,
      limit: 50,
      offset: 0,
    },
  },
  reducers: {
    updateRiskMatrix: (state, action) => {
      state.riskMatrixs = action.payload
    },
    performLogoutRiskMatrix: (state) => {
      state.riskMatrixs = []
    },
    updateRiskMatrixFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const riskMatrixApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskMatrixById: builder.query({
      query: ({ id }) => {
        return {
          url: `/riskmatrix/${id}`,
          method: 'GET',
        }
      },
      providesTags: ['riskMatrixById'],
    }),
    createRiskMatrix: builder.mutation({
      query: ({ name, description, target, country, params, ranges }) => {
        return {
          url: `/riskmatrix`,
          method: 'POST',
          body: {
            name,
            description,
            target,
            country,
            params,
            ranges,
            rmKeyTag: 'TYPE1',
          },
        }
      },
    }),
    updateRiskMatrix: builder.mutation({
      query: ({ id, name, description, target, params, ranges }) => {
        return {
          url: `/riskmatrix/${id}`,
          method: 'PATCH',
          body: {
            name,
            description,
            target,
            params,
            ranges,
            rmKeyTag: 'TYPE1',
          },
        }
      },
      invalidatesTags: ['riskMatrixById'],
    }),
  }),
})

export const { updateRiskMatrix, performLogoutRiskMatrix, updateRiskMatrixFilters } = riskMatrixSlice.actions

export const { useCreateRiskMatrixMutation, useUpdateRiskMatrixMutation, useGetRiskMatrixByIdQuery } = riskMatrixApi

export default riskMatrixSlice.reducer
