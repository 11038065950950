import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckDetailTitle from './components/CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckDeudores = ({ title = '', deudoresCheck, level = 0 }) => {
  const { t } = useTranslation(['common', 'messages'])
  const details = deudoresCheck?.checkDetails

  return (
    <Box className="detail-group" sx={{ padding: '2rem' }}>
      <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1.5rem' }}>
        {title || t('common:deudores')}
      </Typography>

      <CheckDetailTitle
        email={deudoresCheck?.email}
        status={deudoresCheck?.status}
        createdAt={deudoresCheck?.createdAt}
        updatedAt={deudoresCheck?.updatedAt}
      />

      <Typography variant="h4" sx={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
        Resultados
      </Typography>

      {details && details.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          {details.map((detail, index) => (
            <Box
              key={index}
              sx={{
                padding: '1.5rem',
                border: '0.1rem solid',
                borderColor: 'grey.300',
                borderRadius: '0.8rem',
                backgroundColor: 'grey.50',
              }}
            >
              <Typography variant="body1" sx={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
                <strong>Período:</strong> {detail.periodo || '---'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '1rem',
                }}
              >
                {detail.entidades?.length > 0 &&
                  detail.entidades.map((entidad, entidadIndex) => (
                    <Box
                      key={entidadIndex}
                      sx={{
                        padding: '1rem',
                        border: '0.1rem solid',
                        borderColor: 'grey.400',
                        borderRadius: '0.5rem',
                        minWidth: '20rem',
                        flex: '1',
                        backgroundColor: 'grey.100',
                      }}
                    >
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                        <strong>Entidad:</strong> {entidad.entidad || '---'}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                        <strong>Situación:</strong> {entidad.situacion || '---'}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                        <strong>Fecha Situación:</strong>{' '}
                        {entidad.fechaSit1 ? new Date(entidad.fechaSit1).toLocaleDateString() : '---'}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                        <strong>Monto:</strong> ${entidad.monto ? entidad.monto.toLocaleString() : '---'}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                        <strong>Días de atraso:</strong> {entidad.diasAtrasoPago || '---'}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          {deudoresCheck?.checkDetails?.info}
        </Typography>
      )}
    </Box>
  )
}

export default CheckDeudores
