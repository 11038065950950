import { useMemo, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Button, Grid, IconButton, Link, Paper, TableContainer, TablePagination, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@mui/icons-material/Refresh'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useFindKycPersonsQuery } from '../../../../features/kyc/kycSlice'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { FilterModal } from '../../../../components/utilities/FilterModal'
import { kycStatusEnum } from '../../kyc.enums'

const DatatableKycs = ({ text }) => {
  const { t } = useTranslation(['common'])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [showFilters, setShowFilters] = useState(false)
  const [status, setStatus] = useState()

  const { isFetching, isLoading, refetch, data } = useFindKycPersonsQuery({
    limit: rowsPerPage,
    page,
    status,
    text,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      field: 'email',
      headerName: t('common:email'),
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{row.email}</Typography>
      },
    },
    {
      field: 'firstname',
      headerName: t('common:firstName'),
      flex: 0.7,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{row.firstname || '---'}</Typography>
      },
    },
    {
      field: 'lastname',
      headerName: t('common:lastName'),
      flex: 0.7,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{row.lastname || '---'}</Typography>
      },
    },
    // {
    //   field: 'status',
    //   headerName: t('common:status'),
    //   flex: 0.7,
    //   minWidth: 100,
    //   renderCell: ({ row }) => {
    //     return <Typography variant="body2">{t(`common:${row.status}`) || '---'}</Typography>
    //   },
    // },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      flex: 0.8,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(row.createdAt)}</Typography>
      },
    },
    {
      field: 'actions',
      headerName: '',
      flex: 0.7,
      sortable: false,
      renderCell: ({ row }) => (
        <Link component={ReactRouterLink} title={row._id} to={`/kyc/person/${row._id}`}>
          {t('common:viewDetails')}
        </Link>
      ),
    },
  ]

  const { items: kycs, pagination } = useMemo(() => {
    if (data?.data) {
      return data?.data
    }
    return { items: [], pagination: null }
  }, [data])

  return (
    <Paper elevation={0}>
      <TableContainer>
        <Box className="filter-bar">
          <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setShowFilters(true)}>
            {t('common:filters')}
          </Button>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
        <DataGrid
          getRowId={(row) => row._id}
          rows={kycs || []}
          columns={columns}
          hideFooter={true}
          autoHeight={true}
          loading={isFetching || isLoading}
        />
        <TablePagination
          labelRowsPerPage={[]}
          component="div"
          count={pagination?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <FilterModal
        modalOpen={showFilters}
        setModalOpen={setShowFilters}
        handleResetButton={() => setStatus()}
        setPage={setPage}
        handleDispatch={() => refetch()}
      >
        <Box className="modal-box-filters">
          <Box className="modal-box">
            <Typography variant="subtitle3">
              {t('common:by')} {t('common:status')}:
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
            >
              {kycStatusEnum?.map((item, index) => (
                <Grid item key={index}>
                  <Button onClick={() => setStatus(item)} variant={status === item ? 'action' : 'label'}>
                    {t(`common:${item}`)}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </FilterModal>
    </Paper>
  )
}

export default DatatableKycs
