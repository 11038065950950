import './datatable.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Button, IconButton, Link, TablePagination, Tooltip, Typography } from '@mui/material'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Status } from '../../utilities/Status'
import { RiskLevel } from '../../utilities/RiskLevel'
import { useTranslation } from 'react-i18next'
import KybNoRowsOverlay from '../../../pages/kyb/components/KybNoRowsOverlay'
import { useFindKybsQuery } from '../../../services/guenoApi'
import { toLocaleUTCDateString } from '../time/timeHelper'
import CustomLoadingOverlay from '../datagrid/CustomLoadingOverlay'
import CustomColumnMenu from '../datagrid/CustomColumnMenu'

const DatatableKyb = ({
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setModalOpen,
  setIsLoading,
  creationOnNoRows,
  documentNumber = '',
}) => {
  const { t } = useTranslation(['common'])
  const { email } = useSelector((state) => state.login)
  const queryState = useSelector((state) => state.kyb?.filters)
  const { currentCountry, countrySegment } = useSelector((state) => state.kybCountry)
  const [sortField, setSortField] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState(-1)
  const { isFetching, isLoading, refetch, data } = useFindKybsQuery({
    email: queryState.showMyAssignments ? email : null,
    limit: rowsPerPage,
    sortField,
    sortDirection,
    countrySegment,
    ...queryState,
  })

  const columns = [
    {
      field: 'documentNumber',
      headerName: t('common:documentNumber'),
      flex: 3,
      renderCell: ({ row }) => {
        return (
          <Link component={ReactRouterLink} title={row._id} to={`/kyb/${currentCountry}/detail/${row._id}`}>
            <Typography variant="number">{row.companyDetails.documentNumber}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'name',
      headerName: t('common:companyName'),
      flex: 6,
      renderCell: ({ row }) => {
        return <Typography variant="body2">{row.companyDetails.name}</Typography>
      },
    },
    {
      field: 'riskLevel',
      headerName: t('common:riskLevel'),
      flex: 3,
      renderCell: ({ row }) => {
        return (
          <Tooltip
            title={
              row?.riskResult?.riskScoreProbability ? (
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>Impact result: {row?.riskResult?.riskScoreImpact || '---'}</div>
                  <div>Probability result: {row?.riskResult?.riskScoreProbability || '---'}</div>
                  <div>Score: {row.riskScore || '---'}</div>
                </Box>
              ) : (
                <div>Score: {row?.riskScore || '---'}</div>
              )
            }
            placement="right-end"
          >
            <Box>
              <RiskLevel type={row.riskLevel} />
            </Box>
          </Tooltip>
        )
      },
    },
    {
      field: 'status',
      headerName: t('common:status'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Status type={row.status} />
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:creationDate'),
      flex: 3,
      renderCell: ({ row }) => {
        return <Typography variant="number">{toLocaleUTCDateString(row.createdAt)}</Typography>
      },
    },
  ]

  const slots = {
    columnMenu: CustomColumnMenu,
    loadingOverlay: CustomLoadingOverlay,
  }

  if (creationOnNoRows) {
    slots.noRowsOverlay = KybNoRowsOverlay
  }

  const apiRef = useGridApiRef()

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setIsLoading(true)
  }

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]
      setSortField(field)
      setSortDirection(sort === 'asc' ? 1 : -1)
    } else {
      if (sortField === 'createdAt') {
        setSortDirection(sortDirection === -1 ? 1 : -1)
      } else {
        setSortField('createdAt')
        setSortDirection(-1)
      }
      refetch()
    }
    setPage(0)
    setIsLoading(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setIsLoading(true)
  }

  return (
    <Box style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Box className="filter-bar">
        <Button variant="label" endIcon={<MenuOutlinedIcon />} onClick={() => setModalOpen(true)}>
          {t('common:filters')}
        </Button>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Button
            variant="label"
            endIcon={<MenuOutlinedIcon />}
            onClick={() => apiRef.current.showPreferences('columns')}
          >
            {t('common:showHideColumns')}
          </Button>
          <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
            <RefreshIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        getRowHeight={() => 'auto'}
        getRowId={(row) => row._id || row.id}
        rows={Array.isArray(data?.data) ? data?.data : []}
        columns={columns}
        apiRef={apiRef}
        slots={slots}
        slotProps={{
          noRowsOverlay: { defaultDocumentNumber: documentNumber, isKyb: true },
        }}
        hideFooter={true}
        autoHeight
        loading={isFetching || isLoading}
        sortingMode="server"
        sortModel={[{ field: sortField, sort: sortDirection === 1 ? 'asc' : 'desc' }]}
        onSortModelChange={handleSortModelChange}
      />
      <TablePagination
        labelRowsPerPage={[]}
        component="div"
        count={data?.pagination?.totalElements || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default DatatableKyb
