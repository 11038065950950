import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = process.env.REACT_APP_BASEURLRV

export const guenoApiRv = createApi({
  reducerPath: 'guenoApiRv',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().login
      headers.set('Authorization', `Bearer ${accessToken}`)
      return headers
    },
  }),
  endpoints: (builder) => ({
    processTransactions: builder.mutation({
      query: ({ itemId }) => {
        const date = new Date().getTime()
        return {
          url: `/kyt/transactions/all/verify`,
          method: 'POST',
          params: {
            itemId,
            fromDate: date,
            toDate: date,
          },
          body: {},
        }
      },
    }),
  }),
})

export const { useProcessTransactionsMutation } = guenoApiRv
