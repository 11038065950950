import dayjs from 'dayjs'

export const buildTimeStringFromTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  //NO VA CON DOBLE !== PORQUE NO LO TOMA SINO
  if (timestamp && date != 'Invalid Date')
    return `${date.toLocaleDateString()} - ${date.toTimeString().split('GMT')[0]}`
  return '---'
}

export function toLocaleUTCDateString(dateRaw) {
  const date = new Date(dateRaw)
  const timeDiff = date.getTimezoneOffset() * 60000
  const adjustedDate = new Date(date.valueOf() + timeDiff)
  return `${adjustedDate.toLocaleDateString()} - ${adjustedDate.toTimeString().split('GMT')[0]}`
}

export function convertToUTCDate(dateStr, timezone = 0) {
  const date = new Date(dateStr)
  date.setTime(date.getTime() - timezone * 60 * 60 * 1000)

  return date
}

export function formatDate(date) {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
}

export function toCustomUTCDateString(timestamp, timezone = 0) {
  return dayjs.utc(timestamp).utcOffset(timezone).format('DD/MM/YYYY - HH:mm:ss (Z)')
}

export const parseNiceDateAndTimeFromTimeString = (string) => {
  if (!string) {
    return 'Invalid Input'
  }
  try {
    const [datePart, timePart] = string.split('T')
    if (!datePart || !timePart) {
      return 'Invalid Input'
    }
    const [year, month, day] = datePart.split('-').map((num) => parseInt(num, 10))
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return 'Invalid Date'
    }
    const shortYear = year % 100
    const [hours, minutes] = timePart.split(':')
    return `${month}/${day}/${shortYear}-${hours}:${minutes}`
  } catch {
    return ''
  }
}
