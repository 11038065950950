import './Tabs.scss'
import { useEffect, useMemo, useState } from 'react'
import { Box, Typography, Link, IconButton, TablePagination } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Flags } from '../../../../components/common/flag/flags'
import { buildTimeStringFromTimestamp } from '../../../../components/common/time/timeHelper'
import { TableIcons } from '../../../../components/utilities/TableIcons'
import { TableChip } from '../../../../components/utilities/TableChip'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import { useTranslation } from 'react-i18next'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { useFindTransactionsByCaseQuery } from '../../../../features/cases/caseSlice'

const defaultSortModel = { field: 'createdTimestamp', sort: 'desc' }

const TabTransactions = ({ id }) => {
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const {
    data: results,
    isLoading,
    isFetching,
    refetch,
  } = useFindTransactionsByCaseQuery({
    id,
    page,
    limit: rowsPerPage,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })

  // useEffect(() => {
  //   if (results?.data && apiRef.current) {
  //     setTimeout(() => {
  //       apiRef.current.autosizeColumns({
  //         includeHeaders: true,
  //         includeOutliers: true,
  //       })
  //     }, 500)
  //   }
  // }, [apiRef.current, results])

  const columns = [
    {
      field: 'transactionId',
      headerName: 'ID',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Link href={`/transactions/${params?.row?.transactionId}`} style={{ textDecoration: 'none' }}>
            <Typography variant="number">{params?.row?.transactionId}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'type',
      headerName: t('common:type'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Box>{params?.row?.type ? <TableIcons type={params?.row?.type} /> : '---'}</Box>
      },
    },
    {
      field: 'transactionState',
      headerName: t('common:state'),
      flex: 1,
      minWidth: 140,
      renderCell: (params) => {
        return <Box>{params?.row?.transactionState ? <TableIcons type={params?.row?.transactionState} /> : '---'}</Box>
      },
    },
    {
      field: 'createdTimestamp',
      headerName: t('common:date'),
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return buildTimeStringFromTimestamp(params?.row?.timestamp)
      },
    },
    {
      field: 'hitRules',
      headerName: t('common:hitRules'),
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return params?.row?.hitRules.length
      },
    },
    {
      field: 'originUserId',
      headerName: t('common:originUserId'),
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return params?.row?.originUserId
      },
    },
    {
      field: 'originMethod',
      headerName: t('common:originMethod'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box>
            <TableChip action={params?.row?.originPaymentDetails?.method} />
          </Box>
        )
      },
    },
    {
      field: 'originAmount',
      headerName: t('common:originAmount'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params?.row?.originAmountDetails?.transactionAmount?.toLocaleString()
      },
    },
    {
      field: 'originCurrency',
      headerName: t('common:originCurrency'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params?.row?.originAmountDetails?.transactionCurrency
      },
    },
    {
      field: 'originCountry',
      headerName: t('common:originCountry'),

      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <Box> {Flags(params?.row?.originAmountDetails?.country, false, true)} - </Box>
      },
    },
    {
      field: 'destinationUserId',
      headerName: t('common:destinationUserId'),
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'destinationMethod',
      headerName: t('common:destinationMethod'),
      flex: 1,
      minWidth: 230,
      renderCell: (params) => {
        return (
          <Box>
            <TableChip action={params?.row?.destinationPaymentDetails?.method} />
          </Box>
        )
      },
    },
    {
      field: 'destinationAmount',
      headerName: t('common:destinationAmount'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return params?.row?.destinationAmountDetails?.transactionAmount?.toLocaleString()
      },
    },
    {
      field: 'destinationCurrency',
      headerName: t('common:destinationCurrency'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return params?.row?.destinationAmountDetails?.transactionCurrency
      },
    },
    {
      field: 'destinationCountry',
      headerName: t('common:destinationCountry'),
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return <Box> {Flags(params?.row?.destinationAmountDetails?.country, false, true)}</Box>
      },
    },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  return (
    <Box className="kyb-detail-documents">
      <Box className="kyb-documents-top">
        <Typography variant="title">{t('common:transactions')}</Typography>
      </Box>
      <Box style={{ width: '100%', height: '100%' }}>
        <Box
          className="filter-bar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box style={{ flex: 1 }} />

          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={results?.data?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>

          <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
            <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
              <RefreshIcon color="action" />
            </IconButton>
          </Box>
        </Box>

        <DataGridPro
          getRowHeight={() => 'auto'}
          getRowId={(row) => row._id || row.id}
          rows={Array.isArray(results?.data?.transactions) ? results?.data?.transactions : []}
          columns={columns}
          apiRef={apiRef}
          slots={{
            columnMenu: CustomColumnMenu,
            loadingOverlay: CustomLoadingOverlay,
          }}
          hideFooter={true}
          autoHeight
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          isLoading={isLoading || isFetching}
        />
      </Box>
    </Box>
  )
}

export default TabTransactions
