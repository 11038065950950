import { Link, Typography, Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../time/timeHelper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TableChip } from '../../utilities/TableChip'
import { DataGridComponent } from '../datagrid/DataGridComponent'
import { DataGrid } from '@mui/x-data-grid'

const DatatableCases = ({
  hasPermissions,
  cases,
  setCaseToClose,
  setOpenCloseCase,
  count,
  hasTabs,
  rowsPerPage,
  page,
  setRowsPerPage,
  setIsLoading,
  setModalOpen,
  setPage,
  isFromDetail,
  refetch,
  isLoading = false,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const getUserAndNavigate = async (userId) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const userUrl = `${process.env.REACT_APP_BASEURL}/kyt/users/${userId}/${emailClient}`
    fetch(userUrl, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success && res.data) {
          navigate(`/users/${userId}?type=${res.data.type}`)
        }
      })
      .catch((error) => {
        console.error('[CASES: caseUser] --> ', error)
      })
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Link
            href={`/case-management/case/${params.row.id}`}
            title={params.row.maskId ? params.row.maskId : params.row.id}
          >
            <Typography variant="number">{params.row.maskId ? params.row.maskId : params.row.id}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'userId',
      headerName: t('common:userId'),
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              getUserAndNavigate(params.row.userId)
            }}
          >
            <Typography variant="number">{params.row.userId}</Typography>
          </Link>
        )
      },
    },
    {
      field: 'name',
      headerName: t('common:name'),
      minWidth: 300,
      renderCell: (params) => {
        return params.row.name ?? '---'
      },
    },
    {
      field: 'transactions',
      headerName: t('common:transactions'),
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.transactions?.length || 0}</Typography>
      },
    },
    {
      field: 'alerts',
      headerName: t('common:alerts'),
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.generalAlerts?.length || 0}</Typography>
      },
    },
    {
      field: 'uniqueAlertCase',
      headerName: t('common:uniqueAlertCase'),
      minWidth: 100,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.uniqueAlertCase ? 'yes' : 'no'} />
      },
    },
    {
      field: 'status',
      headerName: t('common:caseStatus'),
      minWidth: 280,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.status} />
      },
    },
    {
      field: 'category',
      headerName: t('common:category'),
      minWidth: 280,
    },
    {
      field: 'label',
      headerName: t('common:label'),
      minWidth: 280,
    },
    {
      field: 'assignedRoles',
      headerName: t('common:assignedRoles'),
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Box className="cellOverflow">
            {params.row.assignedRoles?.length
              ? params.row.assignedRoles.map((item) => item.roleName).join(', ')
              : '---'}
          </Box>
        )
      },
    },
    {
      field: 'checklistInstanceIds',
      headerName: t('common:checklists'),
      minWidth: 150,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.checklistInstanceIds?.length || 0}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{toLocaleUTCDateString(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
    {
      field: 'status2',
      headerName: t('common:actions'),
      hide: location?.pathname?.includes('case-management') && !hasPermissions,
      sortable: false,
      filterable: false,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Link
            size="small"
            onClick={() => {
              setCaseToClose({ maskId: params.row.maskId ?? '', id: params.row.id })
              setOpenCloseCase(true)
            }}
          >
            {t('common:update')}
          </Link>
        )
      },
    },
    // ...(process.env.REACT_APP_IS_DEMO && {
    //   field: 'status3',
    //   headerName: t('common:reports'),
    //   flex: 1,
    //   hide: !process.env.REACT_APP_IS_DEMO,
    //   minWidth: 150,
    //   sortable: false,
    //   filterable: false,
    //   renderCell: () => {
    //     return (
    //       <Button
    //         variant="label"
    //         size="small"
    //         onClick={() => {
    //           setOpenCloseCaseReport(true)
    //         }}
    //       >
    //         {t('common:AIreport')}
    //       </Button>
    //     )
    //   },
    // }),
  ]

  return isFromDetail ? (
    <DataGrid
      getRowId={(row) => row._id || row.id}
      rows={cases?.data || []}
      columns={columns}
      hideFooter={true}
      autoHeight={true}
      loading={isLoading}
    />
  ) : (
    <DataGridComponent
      rows={cases || []}
      columns={columns}
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      setIsLoading={setIsLoading}
      setModalOpen={setModalOpen}
      hasTabs={hasTabs}
      isLoading={isLoading}
      refetch={refetch}
    />
  )
}

export default DatatableCases
