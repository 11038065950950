import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Box } from '@mui/material'
import { darken, lighten, styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { FilterModalContainer } from '../../../components/utilities/FilterModalContainer'
import { CURRENCIES, TX_METHODS, TX_STATES, TX_TYPES } from '../transaction/transaction-constants'
import {
  AutocompleteAsyncInputForm,
  AutocompleteInputForm,
  DateTimeInputForm,
  TextInputForm,
} from '../../../components/common/form'
import { useLazyFindTransactionsTagsQuery } from '../../../features/transactions/transactionSlice'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  textAlign: 'center',
  fontWeight: 700,
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const FiltersTransactionsForm = ({ defaultValues, setModalOpen, handleResetButton, handleDispatch, setPage }) => {
  const { emailClient } = useSelector((state) => state.login)
  const { t } = useTranslation(['common'])
  const [transactionsTagsQuery] = useLazyFindTransactionsTagsQuery({ emailClient })

  const { handleSubmit, reset, control, watch, setValue } = useForm({
    mode: 'onTouched',
    defaultValues,
  })

  const [fromDate, toDate] = watch(['fromDate', 'toDate'])

  useEffect(() => {
    if (fromDate && !toDate) {
      setValue('toDate', dayjs(fromDate).hour(23).minute(59).second(59))
    } else if (!fromDate && toDate) {
      setValue('fromDate', dayjs(fromDate).hour(0).minute(0).second(0))
    }
  }, [fromDate, toDate, setValue])

  const handleReset = () => {
    reset()
    handleResetButton()
    setModalOpen(false)
  }

  const fetchTags = async () => {
    const { data } = await transactionsTagsQuery({ emailClient }, true)
    return data?.data?.uniqueResult?.uniqueValues || []
  }

  return (
    <FilterModalContainer
      setModalOpen={setModalOpen}
      handleResetButton={handleReset}
      setPage={setPage}
      handleDispatch={handleSubmit(handleDispatch)}
    >
      <Box className="modal-box-filters">
        <Box>
          <TextInputForm
            name="transactionId"
            control={control}
            label={`${t('common:by')} ID:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="fromDate"
            timeName="fromTime"
            control={control}
            label={`${t('common:by')} ${t('common:initialDate')}:`}
          />
        </Box>
        <Box>
          <DateTimeInputForm
            name="toDate"
            timeName="toTime"
            control={control}
            label={`${t('common:by')} ${t('common:endDate')}:`}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="transactionType"
            control={control}
            label={`${t('common:by')} ${t('common:type')}:`}
            placeholder={`${t('common:by')} ${t('common:type')}`}
            options={TX_TYPES}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="transactionState"
            control={control}
            label={`${t('common:by')} ${t('common:state')}:`}
            placeholder={`${t('common:by')} ${t('common:state')}`}
            options={TX_STATES}
          />
        </Box>
        <Box>
          <TextInputForm
            name="originUserId"
            control={control}
            label={`${t('common:by')} ${t('common:originUserId')}:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="destinationUserId"
            control={control}
            label={`${t('common:by')} ${t('common:destinationUserId')}:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="originCurrency"
            control={control}
            label={`${t('common:by')} ${t('common:originCurrency')}:`}
            placeholder={`${t('common:select')} ${t('common:originCurrency')}`}
            options={CURRENCIES}
            getOptionLabel={(option) => option.aka}
            isOptionEqualToValue={(option, value) => option.aka === value.aka}
            groupBy={(option) => option.type}
            renderGroup={(params) => (
              <li key={params.children.length}>
                <GroupHeader key="group-ori">{params.group}</GroupHeader>
                <GroupItems key="options-ori">{params.children}</GroupItems>
              </li>
            )}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="originMethod"
            control={control}
            label={`${t('common:by')} ${t('common:originMethod')}:`}
            placeholder={`${t('common:select')} ${t('common:originMethod')}`}
            options={TX_METHODS}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="destinationCurrency"
            control={control}
            label={`${t('common:by')} ${t('common:destinationCurrency')}:`}
            placeholder={`${t('common:select')} ${t('common:destinationCurrency')}`}
            options={CURRENCIES}
            getOptionLabel={(option) => option.aka}
            isOptionEqualToValue={(option, value) => option.aka === value.aka}
            groupBy={(option) => option.type}
            renderGroup={(params) => (
              <li key={params.children.length}>
                <GroupHeader key="group-ori">{params.group}</GroupHeader>
                <GroupItems key="options-ori">{params.children}</GroupItems>
              </li>
            )}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="destinationMethod"
            control={control}
            label={`${t('common:by')} ${t('common:destinationMethod')}:`}
            placeholder={`${t('common:select')} ${t('common:destinationMethod')}`}
            options={TX_METHODS}
          />
        </Box>
        <Box>
          <TextInputForm
            name="ruleId"
            control={control}
            label={`${t('common:by')} ${t('common:ruleId')}:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="ruleInstanceId"
            control={control}
            label={`${t('common:by')} ${t('common:ruleInstanceId')}:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="shadowRuleInstanceId"
            control={control}
            label={`${t('common:by')} ${t('common:shadowRuleInstanceId')}:`}
            placeholder={`${t('common:insert')} ID`}
          />
        </Box>
        <Box>
          <AutocompleteInputForm
            name="maxRuleAction"
            control={control}
            label={`${t('common:by')} ${t('common:maxRuleAction')}:`}
            placeholder={`${t('common:select')} ${t('common:maxRuleAction')}`}
            options={['FLAG', 'SUSPEND', 'BLOCK']}
          />
        </Box>
        <Box>
          <TextInputForm
            name="documentNumber"
            control={control}
            label={`${t('common:user')} ${t('common:documentNumber')}:`}
            placeholder={`${t('common:insert')} ${t('common:documentNumber')}`}
          />
        </Box>
        <Box>
          <TextInputForm
            name="legalName"
            control={control}
            label={`${t('common:by')} ${t('common:legalName')} (${t('common:business')}):`}
            placeholder={`${t('common:insert')} ${t('common:legalName')}`}
          />
        </Box>
        <Box>
          <AutocompleteAsyncInputForm
            name="transactionValue"
            control={control}
            label={`${t('common:by')} ${t('common:transactionTagValue')}:`}
            placeholder={`${t('common:select')} ${t('common:transactionTagValue')}`}
            queryFn={fetchTags}
          />
        </Box>

        {/*

        <Box className="modal-box">
          <Typography variant="subtitle3">
            {t('common:by')} {t('common:type')}:
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{ maxWidth: '28rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
          >
            {TX_TYPES?.map((item, index) => (
              <Grid item key={index}>
                <Button
                  onClick={() => {
                    return setTransactionType(item)
                  }}
                  variant={transactionType === item ? 'action' : 'label'}
                >
                  {t(`common:${item}`)}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        */}
      </Box>
    </FilterModalContainer>
  )
}

export default FiltersTransactionsForm
