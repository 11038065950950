import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

// import styles from './styles.module.scss'
import ImageSlider from '../../../../../components/common/toRender/ImgSlider'
import { Flags } from '../../../../../components/common/flag/flags'
import GridItem from '../GridItem'
import { Masonry } from '@mui/lab'
import { useMemo } from 'react'

const renderDate = (obj) => {
  if (!obj) return '---'
  return `${obj.day}/${obj.month}/${obj.year}`
}

const CheckSummary = ({ data }) => {
  const { t } = useTranslation(['common', 'messages'])

  const checkSummary = useMemo(() => {
    if (data?.result?.breakdown) {
      return {
        extractedImages: data.result.breakdown.extractedImages,
        extractedData: data.result.breakdown.extractedData,
      }
    }

    return null
  }, [data])

  if (!checkSummary) return null

  return (
    <Box>
      {checkSummary.extractedImages && <ImageSlider images={checkSummary.extractedImages.map((img) => img.data)} />}
      <Box mb="16px">
        <Typography variant="title2">
          {checkSummary.extractedData.documentDetails.documentSubtypes
            ? checkSummary.extractedData.documentDetails.documentSubtypes[0]
            : '---'}
        </Typography>
      </Box>
      <Masonry columns={{ xs: 1, sm: 2, md: 2 }} spacing={2}>
        <GridItem name={t('common:firstName')} value={checkSummary.extractedData.holderDetails.firstName.join(' ')} />
        <GridItem name={t('common:lastName')} value={checkSummary.extractedData.holderDetails.lastName.join(' ')} />
        <GridItem name={t('common:documentType')} value={checkSummary.extractedData.documentDetails.documentType} />
        <GridItem name={t('common:documentNumber')} value={checkSummary.extractedData.documentDetails.documentNumber} />
        <GridItem
          name={t('common:issuingCountry')}
          value={Flags(checkSummary.extractedData.documentDetails.issuingCountry)}
        />
        <GridItem name={t('common:personalNumber')} value={checkSummary.extractedData.documentDetails.personalNumber} />
        <GridItem
          name={t('common:expirationDate')}
          value={renderDate(checkSummary.extractedData.documentDetails.expirationDate)}
        />
        <GridItem name={t('common:dateOfBirth')} value={renderDate(checkSummary.extractedData.holderDetails.dob)} />
        <GridItem name={`ID ${t('common:MRZLine')} 1`} value={checkSummary.extractedData.mrz.line1} />
        <GridItem name={`ID ${t('common:MRZLine')} 2`} value={checkSummary.extractedData.mrz.line2} />
      </Masonry>
    </Box>
  )
}

export default CheckSummary
