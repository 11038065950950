import { useEffect } from 'react'
import { Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateKybMutation } from '../../../services/guenoApi'
import TextInputForm from '../../../components/common/form/TextInputForm/TextInputForm'

const KybEditAddress = ({ initialValue, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [_, { isLoading }] = useUpdateKybMutation({ fixedCacheKey: 'kyb-update' })

  const schema = yup.object().shape({
    street: yup.string().required(t('common:required')),
    number: yup.number().required(t('common:required')),
    city: yup.string().required(t('common:required')),
    state: yup.string().required(t('common:required')),
    postcode: yup.string().required(t('common:required')),
    country: yup.string().required(t('common:required')),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      street: initialValue.street || '',
      number: initialValue.number || '',
      city: initialValue.city || '',
      state: initialValue.state || '',
      postcode: initialValue.postcode || '',
      country: initialValue.country,
    },
  })

  // useEffect(() => {
  //   if (initialValue) {
  //     setValue('street', initialValue.street || '')
  //     setValue('number', initialValue.number || '')
  //     setValue('city', initialValue.city || '')
  //     setValue('state', initialValue.state || '')
  //     setValue('postcode', initialValue.postcode || '')
  //   }
  // }, [initialValue, setValue])

  const handleFormSubmit = (data) => {
    onSubmit('companyDetails.addresses', [data])
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Box className="modal-box">
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
          <TextInputForm name="street" control={control} label={t('common:street')} />
          <TextInputForm name="number" control={control} label={t('common:number')} />
          <TextInputForm name="city" control={control} label={t('common:city')} />
          <TextInputForm name="state" control={control} label={t('common:state')} />
          <TextInputForm name="postcode" control={control} label={t('common:postcode')} />
          <TextInputForm name="country" control={control} label={t('common:country')} />
        </Box>
      </Box>
      <Box className="modal-button">
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          disabled={!isDirty || Object.keys(errors).length > 0}
          type="submit"
          loading={isLoading}
        >
          {t('common:save')}
        </LoadingButton>
      </Box>
    </form>
  )
}

export default KybEditAddress
