import { Typography } from '@mui/material'
import { toCustomUTCDateString } from '../../../components/common/time/timeHelper'

const RiskMatrixResultTitle = ({ riskResult, total }) => {
  if (!riskResult?.date) {
    return <Typography>-</Typography>
  }

  if (riskResult?.riskScoreImpact) {
    const { riskScoreImpact, riskScoreProbability } = riskResult
    const date = toCustomUTCDateString(riskResult.date)
    return (
      <Typography fontWeight="bold">
        {`${date}  |  Impact result: ${riskScoreImpact} & Probability result: ${riskScoreProbability} => Risk score: ${total}`}
      </Typography>
    )
  }

  return (
    <Typography fontWeight="bold">{`${toCustomUTCDateString(riskResult.date)} | Total score: ${total}`}</Typography>
  )
}

export default RiskMatrixResultTitle
