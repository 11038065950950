import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: {
    workflows: [],
    toAssign: [],
    filters: {
      emailClient: '',
      name: '',
      status: '',
      steps: [],
      page: 0,
      limit: 50,
      offset: 0,
    },
  },
  reducers: {
    updateWorkflows: (state, action) => {
      state.workflows = action.payload
    },
    performLogoutWorkflows: (state) => {
      state.workflows = []
    },
    updateWorkflowsFilters: (state, filters) => {
      state.filters = filters.payload
    },
  },
})

const workflowsApi = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    findWorkflows: builder.query({
      query: ({ limit, page, offset, status, name }) => {
        const params = { limit, page, offset, ...(status?.length && { status }), ...(name?.length && { name }) }

        return {
          url: `/workflows`,
          params,
        }
      },
    }),
  }),
})

export const { updateWorkflows, performLogoutWorkflows, updateWorkflowsFilters } = workflowsSlice.actions

export default workflowsSlice.reducer

export const { useFindWorkflowsQuery } = workflowsApi
