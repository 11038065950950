import { useState } from 'react'
import { Box, Button, Fab, InputLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const ModalOpenCase = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [comment, setComment] = useState('')

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">{t('common:reopenCase')}</Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="modal-box">
        <InputLabel>{t('common:openingReasons')}:</InputLabel>
        <TextField
          multiline
          rows={4}
          size="small"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          placeholder={t('messages:minCharacters')}
        />
      </Box>

      <Box className="modal-filter-buttons">
        <Button variant="outlinedGrey" onClick={onClose}>
          {t('common:close')}
        </Button>
        <Button variant="contained" disabled={comment.length < 5} onClick={() => onSubmit({ comment })}>
          {t('common:save')}
        </Button>
      </Box>
    </Box>
  )
}

export default ModalOpenCase
