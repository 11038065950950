import { createSlice } from '@reduxjs/toolkit'
import guenoApi from '../../services/guenoApi'
import { convertToUTCDate, formatDate } from '../../components/common/time/timeHelper'

const buildCasesParams = (query = {}) => {
  const {
    caseId,
    status,
    fromDate,
    toDate,
    userId,
    firstName,
    legalName,
    documentNumber,
    userType,
    isMyCases,
    roles,
    userKey,
    userValue,
    transactionKey,
    transactionValue,
    timezone = -3,
    searchText,
    searchType,
  } = query

  const params = {
    ...(caseId && { id: caseId }),
    ...(status && { status }),
    ...(userId && { userId }),
    ...(firstName && { firstName }),
    ...(legalName && { legalName }),
    ...(documentNumber && { documentNumber }),
    ...(userType && userType !== 'ALL' && { userType }),
    ...(isMyCases && { assignedRoles: roles }),
    ...(userKey && { userKey }),
    ...(userValue && { userValue }),
    ...(transactionKey && { transactionKey }),
    ...(transactionValue && { transactionValue }),
    ...(searchType && searchText && { [searchType]: searchText }),
  }

  if (fromDate && toDate) {
    params.fromDate = formatDate(convertToUTCDate(fromDate, timezone))
    params.toDate = formatDate(convertToUTCDate(toDate, timezone))
  }

  return params
}

export const defaultCasesFilters = {
  page: 0,
  limit: 10,
  offset: 0,
  caseId: '',
  status: null,
  userId: '',
  fromDate: null,
  toDate: null,
  firstName: '',
  legalName: '',
  documentNumber: '',
  userType: 'ALL',
  userKey: null,
  userValue: null,
  transactionKey: null,
  transactionValue: null,
  isMyCases: false,
  searchType: 'id',
  searchText: '',
}

export const caseSlice = createSlice({
  name: 'case',
  initialState: {
    cases: {},
    caseDetails: {},
    caseUser: {},
    userCases: {},
    caseAdminPanel: {},
    filters: {
      ...defaultCasesFilters,
    },
    selectedCases: {},
  },
  reducers: {
    updateCases: (state, cases) => {
      state.cases = cases.payload
    },
    updateCaseDetails: (state, caseDetails) => {
      state.caseDetails = caseDetails.payload
    },
    updateCaseUser: (state, caseUser) => {
      state.caseUser = caseUser.payload
    },
    updateCasesFilters: (state, filters) => {
      state.filters = filters.payload
    },
    toogleShowMyCases: (state) => {
      state.filters = { ...state.filters, isMyCases: !state.filters.isMyCases }
    },
    resetCasesFilters: (state) => {
      state.filters = {
        ...defaultCasesFilters,
        searchType: state.filters.searchType,
        searchText: state.filters.searchText,
      }
    },
    updateUserCases: (state, userCases) => {
      state.userCases = userCases.payload
    },
    updateCaseAdminPanel: (state, caseAdminPanel) => {
      state.caseAdminPanel = caseAdminPanel.payload
    },
    updateSelectedCases: (state, data) => {
      const { item, added } = data.payload
      if (added) {
        state.selectedCases = { ...state.selectedCases, [item.id]: item }
      } else {
        const { [item.id]: _, ...newSelectedCases } = state.selectedCases
        state.selectedCases = newSelectedCases
      }
    },
  },
})

const endpoints = guenoApi.injectEndpoints({
  endpoints: (builder) => ({
    getCaseById: builder.query({
      query: ({ id }) => {
        return {
          url: `/caseManagement/${id}`,
        }
      },
    }),
    findCases: builder.query({
      query: ({ emailClient, limit, offset, page, sortField = 'updatedAt', sortDirection = -1, ...query }) => {
        const params = buildCasesParams(query)
        return {
          url: `/caseManagement`,
          params: {
            emailClient,
            limit,
            offset,
            page,
            sortField,
            sortDirection,
            ...params,
          },
        }
      },
    }),
    findCasesTags: builder.query({
      query: () => {
        return {
          url: `/caseManagement/tags`,
        }
      },
      keepUnusedDataFor: 60,
    }),
    findTransactionsByCase: builder.query({
      query: ({ id, page, limit, sortField, sortDirection }) => {
        return {
          url: `/caseManagement/${id}/transactions/`,
          params: {
            page,
            limit,
            sortField,
            sortDirection,
          },
        }
      },
    }),
    findTransactionsAlertsByCase: builder.query({
      query: ({ emailClient, id, limit, page, type, sortField, sortDirection }) => {
        return {
          url: `/caseManagement/${id}/alerts`,
          params: {
            emailClient,
            limit,
            page,
            type,
            sortField,
            sortDirection,
          },
        }
      },
    }),
    getTimelineByCase: builder.query({
      query: ({ id, page, limit }) => {
        return {
          url: `/caseManagement/${id}/timeline`,
          params: {
            page,
            limit,
          },
        }
      },
    }),
    updateCaseComment: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}/comments`,
        method: 'POST',
        body,
      }),
    }),
    updateCase: builder.mutation({
      query: ({ body, caseToClose }) => ({
        url: `/caseManagement/${caseToClose.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    getCaseAdminPanel: builder.query({
      query: ({ emailClient }) => {
        return {
          url: `/caseAdminPanel/${emailClient}`,
        }
      },
    }),
  }),
})

export const {
  updateCases,
  updateCaseDetails,
  updateToAssign,
  updateCaseUser,
  updateCasesFilters,
  resetCasesFilters,
  toogleShowMyCases,
  updateUserCases,
  updateCaseAdminPanel,
  updateCaseAdminToAssing,
  updateSelectedCases,
} = caseSlice.actions

export default caseSlice.reducer

export const {
  useGetCaseByIdQuery,
  useFindCasesQuery,
  useLazyFindCasesTagsQuery,
  useGetCaseAdminPanelQuery,
  useUpdateCaseCommentMutation,
  useUpdateCaseMutation,
  useFindTransactionsByCaseQuery,
  useGetTimelineByCaseQuery,
  useFindTransactionsAlertsByCaseQuery,
} = endpoints
