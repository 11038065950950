import '../../../../pages/case-management/cases/case.scss'
import { useMemo, useState } from 'react'
import { Box, IconButton, Link, TablePagination, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { buildTimeStringFromTimestamp, toLocaleUTCDateString } from '../../../../components/common/time/timeHelper'
import { TableChip } from '../../../../components/utilities/TableChip'
import { useNavigate } from 'react-router-dom'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import RefreshIcon from '@mui/icons-material/Refresh'
import CustomColumnMenu from '../../../../components/common/datagrid/CustomColumnMenu'
import CustomLoadingOverlay from '../../../../components/common/datagrid/CustomLoadingOverlay'
import { useFindCasesQuery } from '../../../../features/cases/caseSlice'

const defaultSortModel = { field: 'updatedAt', sort: 'desc' }

const TabUserCases = ({ userId }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['common'])
  const apiRef = useGridApiRef()
  const { accessToken, emailClient } = useSelector((state) => state.login)
  const [sortModel, setSortModel] = useState([defaultSortModel])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { field, sort } = useMemo(() => (sortModel?.length ? sortModel[0] : defaultSortModel), [sortModel])

  const {
    data: results,
    isLoading,
    isFetching,
    refetch,
  } = useFindCasesQuery({
    emailClient,
    page,
    userId,
    limit: rowsPerPage,
    sortField: field,
    sortDirection: sort === 'desc' ? -1 : 1,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getUserAndNavigate = async (userId) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }

    const userUrl = `${process.env.REACT_APP_BASEURL}/kyt/users/${userId}/${emailClient}`
    fetch(userUrl, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success && res.data) {
          navigate(`/users/${userId}?type=${res.data.type}`)
        }
      })
      .catch((error) => {
        console.error('[CASES: caseUser] --> ', error)
      })
  }

  const handleSortModelChange = (sortModel) => {
    setSortModel(sortModel)
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Link
            href={`/case-management/case/${params.row.id}`}
            title={params.row.maskId ? params.row.maskId : params.row.id}
          >
            <Typography variant="number">{params.row.maskId ? params.row.maskId : params.row.id}</Typography>
          </Link>
        )
      },
      sortable: false,
    },
    {
      field: 'userId',
      headerName: t('common:userId'),
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              getUserAndNavigate(params.row.userId)
            }}
          >
            <Typography variant="number">{params.row.userId}</Typography>
          </Link>
        )
      },
      sortable: false,
    },
    {
      field: 'name',
      headerName: t('common:name'),
      minWidth: 300,
      renderCell: (params) => {
        return params.row.name ?? '---'
      },
      sortable: false,
    },
    {
      field: 'transactions',
      headerName: t('common:transactions'),
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.transactions?.length || 0}</Typography>
      },
    },
    {
      field: 'generalAlerts',
      headerName: t('common:alerts'),
      minWidth: 100,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.generalAlerts?.length || 0}</Typography>
      },
    },
    {
      field: 'uniqueAlertCase',
      headerName: t('common:uniqueAlertCase'),
      minWidth: 100,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.uniqueAlertCase ? 'yes' : 'no'} />
      },
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('common:caseStatus'),
      minWidth: 280,
      renderCell: (params) => {
        return <TableChip noIcon={true} action={params.row.status} />
      },
    },
    {
      field: 'category',
      headerName: t('common:category'),
      minWidth: 280,
      sortable: false,
    },
    {
      field: 'label',
      headerName: t('common:label'),
      minWidth: 280,
      sortable: false,
    },
    {
      field: 'assignedRoles',
      headerName: t('common:assignedRoles'),
      minWidth: 240,
      renderCell: (params) => {
        return (
          <Box className="cellOverflow">
            {params.row.assignedRoles?.length
              ? params.row.assignedRoles.map((item) => item.roleName).join(', ')
              : '---'}
          </Box>
        )
      },
    },
    {
      field: 'checklistInstanceIds',
      headerName: t('common:checklists'),
      minWidth: 150,
      renderCell: (params) => {
        return <Typography variant="number">{params.row.checklistInstanceIds?.length || 0}</Typography>
      },
    },
    {
      field: 'createdAt',
      headerName: t('common:createdAt'),
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{toLocaleUTCDateString(params.row.createdAt)}</Typography>
      },
    },
    {
      field: 'updatedAt',
      headerName: t('common:updatedAt'),
      minWidth: 200,
      renderCell: (params) => {
        return <Typography variant="number">{buildTimeStringFromTimestamp(params.row.updatedAt)}</Typography>
      },
    },
  ]

  return (
    <Box className="kyb-detail-documents">
      <Box className="kyb-documents-top">
        <Typography variant="title">{t('common:cases')}</Typography>
      </Box>
      <Box style={{ width: '100%', height: '100%' }}>
        <Box
          className="filter-bar"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box style={{ flex: 1 }} />

          <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TablePagination
              labelRowsPerPage={[]}
              component="div"
              count={results?.pagination?.totalElements || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </Box>

          <Box style={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
            <IconButton onClick={refetch} disabled={isLoading} title={t('common:refresh')}>
              <RefreshIcon color="action" />
            </IconButton>
          </Box>
        </Box>

        <DataGridPro
          getRowHeight={() => 'auto'}
          getRowId={(row) => row._id || row.id}
          rows={Array.isArray(results?.data) ? results?.data : []}
          columns={columns}
          apiRef={apiRef}
          slots={{
            columnMenu: CustomColumnMenu,
            loadingOverlay: CustomLoadingOverlay,
          }}
          hideFooter={true}
          autoHeight
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          isLoading={isLoading || isFetching}
        />
      </Box>
    </Box>
  )
}

export default TabUserCases
