import './styles/ocPreview.scss'
import { useTranslation } from 'react-i18next'
import { AccordionDetails, Box, Button, Chip, Divider, Fab, Modal, Paper, Typography } from '@mui/material'
import { TableChip } from '../../../components/utilities/TableChip'
import { toLocaleUTCDateString } from '../../../components/common/time/timeHelper'
import { renderObject } from '../../../components/utilities/RenderObject'
import { camelCaseToPhrase } from '../../../components/common/toRender/camelCAseToPhrase'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import { useState } from 'react'
import { Accordion, AccordionSummary } from '../../../components/common/Accordion/Accordion'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

export const OCDrawer = ({ open, currentRequestToAoR, isEvaluatedBy, handleAoRCurrentRequest }) => {
  const { isOpen, setIsOpen } = open
  const { t } = useTranslation(['common'])
  const [expanded, setExpanded] = useState(false)

  const handleRejectButton = (currentRequestToAoRId) => {
    handleAoRCurrentRequest(currentRequestToAoRId)
    setIsOpen(false)
    setExpanded(false)
  }

  const handleApproveButton = (currentRequestToAoRId) => {
    handleAoRCurrentRequest(currentRequestToAoRId, true)
    setIsOpen(false)
    setExpanded(false)
  }

  const RenderMethod = ({ method }) => {
    switch (method) {
      case 'POST':
        return <Chip label={t('common:create').toUpperCase()} />
      case 'PATCH':
        return <Chip label={t('common:update').toUpperCase()} />
      case 'PUT':
        return <Chip label={t('common:update').toUpperCase()} />
      case 'DELETE':
        return <Chip label={t('common:delete').toUpperCase()} />

      default:
        break
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(false)
        setExpanded(false)
      }}
    >
      <Box className="modal">
        <Box className="modal-top">
          <Box className="modal-titles">
            <Typography variant="title">{t('common:details')}</Typography>
          </Box>
          <Fab
            variant="close"
            onClick={() => {
              setIsOpen(false)
              setExpanded(false)
            }}
          >
            <CloseOutlinedIcon />
          </Fab>
        </Box>

        <Box className="preview-grid">
          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:status')}</Typography>
              <TableChip action={currentRequestToAoR?.status?.toLowerCase()} noIcon={true} />
            </Box>
          </Paper>

          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:service')}</Typography>
              <Typography variant="h5">
                {currentRequestToAoR?.route ? (
                  <TableChip
                    action={camelCaseToPhrase(currentRequestToAoR?.route?.split('/')[4])?.toUpperCase()}
                    noIcon={true}
                    border={true}
                  />
                ) : (
                  '---'
                )}
              </Typography>
            </Box>
          </Paper>

          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:requestedBy')}</Typography>
              <Typography variant="h5">
                {currentRequestToAoR?.requestedBy || currentRequestToAoR?.email || '---'}
              </Typography>
            </Box>
          </Paper>

          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:evaluatedBy')}</Typography>
              <Typography variant="h5">
                {currentRequestToAoR?.evaluatedBy !== '------' && currentRequestToAoR?.status !== 'PENDING'
                  ? currentRequestToAoR?.evaluatedBy
                  : 'Request not evaluated yet'}
              </Typography>
            </Box>
          </Paper>

          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:createdAt')}</Typography>
              <Typography variant="h5">{toLocaleUTCDateString(currentRequestToAoR?.createdAt)}</Typography>
            </Box>
          </Paper>

          <Paper variant="colorPrimary">
            <Box className="preview-item">
              <Typography variant="subtitle1">{t('common:updatedAt')}</Typography>
              <Typography variant="h5">{toLocaleUTCDateString(currentRequestToAoR?.updatedAt)}</Typography>
            </Box>
          </Paper>
        </Box>

        <Divider />
        <Box className="preview-block">
          <Box className="preview-top">
            <Box className="preview-flex">
              <Typography variant="title" sx={{ ml: 1 }}>
                {t('common:request')}
              </Typography>
              <RenderMethod method={currentRequestToAoR?.method || '---'} />
            </Box>
            <Typography variant="title2" sx={{ mr: 1 }}>
              ID: {currentRequestToAoR?._id}{' '}
            </Typography>
          </Box>

          <Paper variant="rootOutlined">
            <Box className="preview-item">
              <Typography variant="subtitle1">URL</Typography>
              <Typography variant="number">{currentRequestToAoR?.route || '---'}</Typography>
            </Box>
          </Paper>

          {(currentRequestToAoR?.params || currentRequestToAoR?.query) && (
            <Paper variant="rootOutlined">
              <Box className="preview-item">
                <Typography variant="subtitle1">{t('common:parameters')}</Typography>
                {currentRequestToAoR?.params && <Box>{renderObject(currentRequestToAoR?.params)}</Box>}
                {currentRequestToAoR?.query && <Box>{renderObject(currentRequestToAoR?.query)}</Box>}
              </Box>
            </Paper>
          )}

          {currentRequestToAoR?.body &&
            typeof currentRequestToAoR.body === 'object' &&
            !Array.isArray(currentRequestToAoR.body) &&
            Object.keys(currentRequestToAoR.body).length > 0 && (
              <Accordion variant="medium" expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={null}>
                  <Box className="case-detail-accordions-buttons">
                    <Typography variant="subtitle1">{t('common:requestDetails')}</Typography>
                    <Button
                      variant="outlinedBlank"
                      onClick={(e) => {
                        e.stopPropagation()
                        return setExpanded(!expanded)
                      }}
                    >
                      {expanded ? <KeyboardDoubleArrowDownIcon /> : <KeyboardDoubleArrowUpIcon />}
                    </Button>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <pre>
                      <code>
                        <Typography variant="number">{JSON.stringify(currentRequestToAoR?.body, null, 2)}</Typography>
                      </code>
                    </pre>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
        </Box>
        {isEvaluatedBy && (
          <Box className="preview-sub-buttons">
            <Button
              variant="outlinedGrey"
              onClick={() => handleRejectButton(currentRequestToAoR._id)}
              disabled={!isEvaluatedBy || currentRequestToAoR?.status !== 'PENDING'}
            >
              {t('common:reject')}
            </Button>

            <Button
              variant="contained"
              disabled={!isEvaluatedBy || currentRequestToAoR?.status !== 'PENDING'}
              onClick={() => handleApproveButton(currentRequestToAoR._id)}
            >
              {t('common:approve')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
