import { Box, Typography, Divider } from '@mui/material'
import CheckDetailTitle from './components/CheckDetailTitle'

const variantByLevel = {
  0: 'h3',
  1: 'h4',
}

const CheckRepet = ({ title = '', repetCheck, level = 0 }) => {
  const details = repetCheck?.checkDetails?.items

  return (
    <Box className="detail-group" sx={{ padding: '2rem' }}>
      <Typography variant={variantByLevel[level] || variantByLevel[0]} sx={{ marginBottom: '1.5rem' }}>
        {title || 'Repet'}
      </Typography>

      <CheckDetailTitle
        email={repetCheck?.email}
        status={repetCheck?.status}
        createdAt={repetCheck?.createdAt}
        updatedAt={repetCheck?.updatedAt}
      />

      <Typography variant="h4" sx={{ fontSize: '1.25rem', marginBottom: '1rem' }}>
        Resultados
      </Typography>

      {details?.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          {details.map((item, index) => (
            <Box
              key={index}
              sx={{
                padding: '1.5rem',
                border: '0.1rem solid',
                borderColor: 'grey.300',
                borderRadius: '0.8rem',
                backgroundColor: 'grey.50',
              }}
            >
              <Typography variant="body1" sx={{ fontSize: '1.1rem', marginBottom: '1rem' }}>
                <strong>Nombre:</strong> {item.firstName || '---'}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Tipo de Lista:</strong> {item.unListType || '---'}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Número de Referencia:</strong> {item.referenceNumber || '---'}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Listado el:</strong> {item.listedOn ? new Date(item.listedOn).toLocaleDateString() : '---'}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Comentarios:</strong> {item.comments || '---'}
              </Typography>
              {item.lastDayUpdated?.length > 0 && (
                <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                  <strong>Última actualización:</strong> {item.lastDayUpdated[0]?.value || '---'}
                </Typography>
              )}
              <Divider sx={{ margin: '1rem 0' }} />
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Alias:</strong>
              </Typography>
              {item.entityAlias.map((alias, aliasIndex) => (
                <Typography key={aliasIndex} variant="body2" sx={{ fontSize: '1rem' }}>
                  {alias.quality || '---'}: {alias.aliasName || '---'}
                </Typography>
              ))}
              <Divider sx={{ margin: '1rem 0' }} />
              <Typography variant="body2" sx={{ fontSize: '1rem', marginBottom: '0.5rem' }}>
                <strong>Direcciones:</strong>
              </Typography>
              {item.entityAddress?.map((address, addressIndex) =>
                address.street && address.street.trim() !== '' ? (
                  <Typography key={addressIndex} variant="body2" sx={{ fontSize: '1rem' }}>
                    {address.street.trim()}, {address.city || '---'}, {address.country || '---'}
                  </Typography>
                ) : (
                  <Typography key={addressIndex} variant="body2" sx={{ fontSize: '1rem' }}>
                    Dirección no especificada.
                  </Typography>
                ),
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant="body1" sx={{ fontSize: '1rem' }}>
          No se encontraron coincidencias.
        </Typography>
      )}
    </Box>
  )
}

export default CheckRepet
