import { useState } from 'react'
import AutocompleteInputForm from '../AutocompleteInputForm/AutocompleteInputForm'

const AutocompleteAsyncInputForm = ({ queryFn = () => [], ...props }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const handleOpen = () => {
    setOpen(true)
    // empty space
    ;(async () => {
      setLoading(true)
      const options = await queryFn()
      setLoading(false)

      setOptions(options)
    })()
  }

  const handleClose = () => {
    setOpen(false)
    setOptions([])
  }

  return (
    <AutocompleteInputForm
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      loading={loading}
      options={options}
    />
  )
}

export default AutocompleteAsyncInputForm
