import { Box, Paper, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { FileUploader } from 'react-drag-drop-files'
import { useTranslation } from 'react-i18next'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './FileInputForm.module.scss'

const FileSingleInputForm = ({
  name,
  control,
  label,
  fileTypes = [],
  tooltip = '',
  maxSize = 150,
  required = false,
}) => {
  const { t } = useTranslation(['common', 'messages'])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              {!!label && <Typography variant="subtitle2">{label}</Typography>}
              {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
              {!!tooltip && <RenderTooltip title={tooltip} />}
            </Box>
            <Paper elevation={0} variant="rootDashed" className={styles.draggableMain}>
              <FileUploader
                handleChange={field.onChange}
                name="file"
                label="Upload file"
                types={fileTypes}
                maxSize={maxSize}
              >
                {!field.value && (
                  <Box className={styles.draggableContainer}>
                    <Box className={styles.draggableIcon}></Box>
                    <Typography variant="title2">{t('messages:kyt-import.file.label', { size: maxSize })}</Typography>
                    <Box>
                      <Typography variant="subtitle4">{t('common:or')} </Typography>
                      <Typography sx={{ textDecoration: 'underline' }} variant="subtitle4" color="secondary">
                        {t('common:browseMedia')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {field.value && (
                  <Box className={styles.draggableContainer}>
                    <Box className="color-button" sx={{ background: '#E4DDFF' }}>
                      <Typography variant="subtitle1" sx={{ color: '#7E65D5' }}>
                        {field.value.type.split('/')[1].toUpperCase()}
                      </Typography>
                    </Box>

                    <Typography variant="h6">{field.value.name}</Typography>
                  </Box>
                )}
              </FileUploader>
            </Paper>
          </Box>
        )
      }}
    />
  )
}

export default FileSingleInputForm
