import { Box, Button, Fab, InputLabel, TextField, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

const ModalCreateComment = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [comment, setComment] = useState('')

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">{t('common:postComment')}</Typography>
          <Typography variant="subtitle3">{t('messages:commentDesc')}</Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>
      <Box className="modal-box">
        <InputLabel>{t('common:comment')}:</InputLabel>
        <TextField
          multiline
          placeholder={t('messages:minCharacters')}
          required
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Box>
      <Box className="modal-button">
        <Button
          size="small"
          color="secondary"
          variant="contained"
          disabled={comment.length < 5}
          onClick={() => onSubmit({ comment })}
        >
          {t('common:publishComment')}
        </Button>
      </Box>
    </Box>
  )
}

export default ModalCreateComment
