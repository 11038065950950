import { useState } from 'react'
import { Box, Button, Divider, Fab, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'

const ModalCloseCase = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [comment, setComment] = useState('')
  const [status, setStatus] = useState('CLOSED_WITHOUT_ACTIONS')

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">{t('common:closeCase')}</Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="modal-box">
        <InputLabel>{t('common:newStatus')}:</InputLabel>
        <Select fullWidth size="small" value={status} onChange={(e) => setStatus(e.target.value)} displayEmpty>
          <MenuItem disabled value="">
            <Typography variant="subtitle3">
              {t('common:select')} {t('common:newStatus')}
            </Typography>
          </MenuItem>
          {['CLOSED_WITH_ACTIONS', 'CLOSED_WITHOUT_ACTIONS']?.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`common:${item}`)}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Divider />

      <Box className="modal-box">
        <InputLabel>{t('common:updatingReasons')}:</InputLabel>
        <TextField
          multiline
          rows={4}
          size="small"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          placeholder={t('messages:minCharacters')}
        />
      </Box>

      <Box className="modal-filter-buttons">
        <Button variant="outlinedGrey" onClick={onClose}>
          {t('common:close')}
        </Button>
        <Button variant="contained" disabled={comment.length < 5} onClick={() => onSubmit({ status, comment })}>
          {t('common:save')}
        </Button>
      </Box>
    </Box>
  )
}

export default ModalCloseCase
