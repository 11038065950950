import { useState } from 'react'
import { Box, Button, Divider, Fab, InputLabel, TextField, Typography } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTranslation } from 'react-i18next'

const ModalScaleCase = ({ onClose, onSubmit, actualStep }) => {
  const { t } = useTranslation(['common', 'messages'])
  const [comment, setComment] = useState('')

  return (
    <Box className="modal">
      <Box className="modal-top">
        <Box className="modal-titles">
          <Typography variant="title">{t('common:scale')}</Typography>
        </Box>
        <Fab variant="close" onClick={onClose}>
          <CloseOutlinedIcon />
        </Fab>
      </Box>

      <Box className="case-workflow-info">
        <Typography variant="subtitle3">{t('messages:scaleCaseInfo')}</Typography>
        <Typography variant="subtitle2">
          {t('common:currentStep')}: {actualStep + 1}
        </Typography>
      </Box>

      <Divider />

      <Box className="modal-box">
        <InputLabel>{t('common:comment')}:</InputLabel>
        <TextField
          multiline
          placeholder={t('messages:minCharacters')}
          required
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Box className="case-workflow-info">
          <Typography variant="subtitle3">{t('messages:commentDesc')}</Typography>
        </Box>
      </Box>

      <Box className="modal-filter-buttons">
        <Button variant="outlinedGrey" onClick={onClose}>
          {t('common:close')}
        </Button>
        <Button variant="contained" disabled={comment.length < 5} onClick={() => onSubmit({ comment })}>
          {t('common:save')}
        </Button>
      </Box>
    </Box>
  )
}

export default ModalScaleCase
