export const searchTypes = ['documentNumber', 'name', 'id']
export const documentByTypeAndCountry = {
  kyb: {
    BR: ['CNPJ'],
    CO: ['NIT'],
    AR: ['CUIT'],
  },
  kyc: {
    BR: ['CPF'],
    CO: ['CC', 'CE'],
    AR: ['CUIL'],
  },
}

export const rolesTypesRelatedPersons = [
  'attorney',
  'employee',
  'relatedPersons',
  'familyMember',
  'accountant',
  'lawyer',
  'others',
]

export const rolesTypesShareHolder = [
  'masterAttorney',
  'attorney',
  'employee',
  'relatedPersons',
  'familyMember',
  'accountant',
  'lawyer',
  'others',
]

export const iconByCountry = {
  brazil: 'br',
  colombia: 'co',
}

export const colorsByStatus = {
  IN_PROGRESS: 'var(--alert-dark)',
  DENIED: 'var(--error-dark)',
  APPROVED: 'var(--success-dark)',
  PROCESSED: 'var(--success-dark)',
}
