import { Box, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { RenderTooltip } from '../../../utilities/RenderTooltip'
import styles from './TextInputForm.module.scss'

const TextInputForm = ({
  name,
  control,
  label,
  placeholder = '',
  tooltip = '',
  required = false,
  type = 'text',
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box className={styles.container}>
            <Box className={styles.label}>
              <Typography variant="subtitle2">{label}</Typography>
              {!!label && !!required && <Typography variant="subtitle3">(*)</Typography>}
              {!!tooltip && <RenderTooltip title={tooltip} />}
            </Box>

            <TextField
              fullWidth
              helperText={fieldState.error ? fieldState.error.message : null}
              error={!!fieldState.error}
              type={type}
              placeholder={placeholder}
              {...field}
              {...props}
            />
          </Box>
        )
      }}
    />
  )
}

export default TextInputForm
